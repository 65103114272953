import React, { useContext } from 'react';

import { useLocation, useNavigate } from '@reach/router';
import { UserContext } from '../../Contexts/User';

function RequireLogin<P>(
  Func: React.FunctionComponent<P>,
  role: 'none' | 'student' | 'teacher' | 'admin' = 'student'
): React.FunctionComponent<P> {
  const Auth = (props: P) => {
    const { user } = useContext(UserContext);

    const navigate = useNavigate();
    const location = useLocation();

    if (user.loading) {
      return <div>loading;</div>;
    }
    if (role === 'none') {
      return <Func {...props} />;
    }
    if (!user.loggedIn) {
      navigate(`/?logInOpen=true`);
      return <div>redirecting</div>;
    }
    let levels = {
      student: 1,
      teacher: 2,
      admin: 3,
    };

    // @ts-ignore
    let val = levels[user.role];

    if (val === undefined || val > levels[role]) {
      return <div>you need to be {role} to access this page</div>;
    }

    return <Func {...props} />;
  };

  return Auth;
}

export default RequireLogin;
