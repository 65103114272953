import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Backdrop,
  Dialog,
  Container,
  InputAdornment,
  CircularProgress,
  Fade,
} from '@material-ui/core';
import styles from './PlayerView.module.scss';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PlayState, WSProps } from '../../../Home/Play';
import FinancialReport from '../Reports/FinancialReport';
import { useTranslation } from 'react-i18next';
import { replaceComma } from '../../../../lib/helpers';
import { isMobile } from 'react-device-detect';
import Constants from '../../../../lib/Constants';
import CompetitionReport from '../Reports/CompetitionReport';
import ChangeLanguage from '../../../../lib/ChangeLanguage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
);

export interface Player {
  //misc info
  balance: number;
  stock: number;
  rep: number;
  week: number;
  //decision info
  price: number;
  amount: number;
  advertisement: number;
  answered?: boolean;
}

export interface PublicInfo {
  demand: number;
  advert: number;
  wholesalePrice: number;
  wages: number;
  fixedCosts: number;
  totalPlayers: number;
  averagePrice: number;
  totalSold: number;
  totalAdvert: number;
  totalStock: number;
  totalUnfilledOrders: number;
}

export interface PlayerHistoryInfo {
  price: number;
  amount: number;
  advertisement: number;

  startStock: number;
  sold: number;
  totalOrders: number;

  profit: number;

  endBalance: number;

  ranking: number;
}

export interface CompetitionPlayerType {
  name: string;
  values: Array<{ price: number; profit: number; endBalance: number }>;
}

export interface PlayerData {
  history: Array<PlayerHistoryInfo & PublicInfo>;
  competeArr: Array<CompetitionPlayerType>;
  current: Player;
  allowAdvertisement: boolean;
  allowAmount: boolean;
}

export interface Props extends PlayerData, PlayState, WSProps {}

function PlayerView(props: Props) {
  const classes = useStyles();
  const [resellPrice, setResellPrice] = useState('');
  const [resellPriceError, setResellPriceError] = useState('');
  const [amountToBuy, setAmountToBuy] = useState('');
  const [amountToBuyError, setAmountToBuyError] = useState('');
  const [advertisement, setAdvertisement] = useState('');
  const [advertisementError, setAdvertisementError] = useState('');
  const [financialModal, setFinancialModal] = useState(false);
  const [competitionModal, setCompetitionModal] = useState(false);
  const [answerButton, setAnswerButton] = useState('playerView.sendGameData');
  const [loading, setLoading] = useState(false);
  const [newWeekAlert, setNewWeekAlert] = useState(false);
  const { t } = useTranslation();

  const onSend = async () => {
    const displayLoading = setTimeout(() => {
      setLoading(true);
    }, 800);
    const arr: Array<[string, React.Dispatch<React.SetStateAction<string>>, string, boolean]> = [
      [resellPrice, setResellPriceError, resellPriceError, true],
      [amountToBuy, setAmountToBuyError, amountToBuyError, props.allowAmount],
      [advertisement, setAdvertisementError, advertisementError, props.allowAdvertisement],
    ];
    if (validateInput()) {
      clearTimeout(displayLoading);
      setLoading(false);
      return;
    }

    for (let k of arr) {
      if (!arr[3]) {
        k[0] = '0';
      } else if (k[0].length === 0) {
        k[1](t('playerView.inputError'));
      }
    }
    for (let k of arr) {
      if (k[2].length > 0) {
        clearTimeout(displayLoading);
        setLoading(false);
        return;
      }
    }

    const resp = await fetch(`${Constants.url}/SupplyDemand/setAnswer?joinToken=${props.joinToken}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        price: Number(replaceComma(resellPrice)),
        amount: Number(replaceComma(amountToBuy)),
        advertisement: Number(replaceComma(advertisement)),
      }),
    });
    const data = await resp.json();
    if (resp.status === 200) {
      setAnswerButton('playerView.answerChanged');
      setTimeout(() => {
        setAnswerButton('playerView.changeAnswerButton');
      }, 2000);
    } else {
      //console.error('sumbit error: ', data);
      //todo: error handling
    }
    clearTimeout(displayLoading);
    setLoading(false);
  };
  const week = props.history.length;
  useEffect(() => {
    setNewWeekAlert(true);
    setAnswerButton('playerView.sendGameData');
    if (props.history.length > 0) {
      let h = props.current;
      setResellPrice(h.price.toString());
      setAmountToBuy(h.amount.toString());
      setAdvertisement(h.advertisement.toString());
    }
  }, [week]);

  const validateInput = () => {
    const arr = [
      [resellPrice, ''],
      [amountToBuy, ''],
      [advertisement, ''],
    ];

    let values = [0, 0, 0];

    for (let k of arr) {
      let s = k[0] as string;

      if (s.length === 0) {
        continue;
      }
      s = replaceComma(s);
      if (isNaN(Number(s)) || Number(s) < 0 || s.length === 0) {
        k[1] = t('incorrectInput');
      } else {
        values.push(Number(s));
        let parts = s.split('.');
        if (parts.length >= 2) {
          if (parts[parts.length - 1].length > 2) {
            k[1] = t('playerView.tooAccurateError');
          }
        }
      }
    }
    if (arr[1][1].length === 0 && arr[2][1].length === 0) {
      let amount = Number(replaceComma(amountToBuy));
      let adv = Number(replaceComma(advertisement));
      let wp = props.history[props.history.length - 1].wholesalePrice;
      let money = props.history[props.history.length - 1].endBalance;
      if (amount * wp + adv > Math.max(money, 0)) {
        arr[1][1] = t('playerView.insufficientFundsError');
        arr[2][1] = t('playerView.insufficientFundsError');
      }
    }
    setResellPriceError(arr[0][1]);
    setAmountToBuyError(arr[1][1]);
    setAdvertisementError(arr[2][1]);
    return arr[0][1].length > 0 || arr[1][1].length > 0 || arr[2][1].length > 0;
  };

  useEffect(() => {
    validateInput();
  }, [resellPrice, amountToBuy, advertisement]);

  return (
    <Container maxWidth="sm">
      <div className={styles.playerViewGrid}>
        <Backdrop
          className={classes.backdrop}
          //@ts-ignore
          open={props.joiningPhase}
        >
          <p className={styles.dialogText}>{t('playerView.waitForGame')}</p>
        </Backdrop>
        <FinancialReport isOpen={financialModal} setIsOpen={setFinancialModal} history={props.history} />
        <CompetitionReport
          isOpen={competitionModal}
          setIsOpen={setCompetitionModal}
          history={props.history}
          competeArr={props.competeArr}
        />
        <div className={styles.nameAndWeek}>
          <p className={styles.weekTitle}>{t('playerView.weekTitle', { week: week })}</p>
          <span style={{ textAlign: 'center' }}>{props.gameState.name}</span>
        </div>

        <Button className={styles.openModalButton} onClick={() => setFinancialModal(true)} variant="contained">
          {t('global.financialReport')}
        </Button>
        <Button className={styles.openModalButton} onClick={() => setCompetitionModal(true)} variant="contained">
          {t('global.competitionReport')}
        </Button>
        <TextField
          inputProps={isMobile ? { pattern: '[0-9]*', type: 'number', inputmode: 'decimal' } : {}}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span style={{ color: '#fff' }}>€</span>
              </InputAdornment>
            ),
          }}
          label={t('playerView.resellPriceInput')}
          variant="outlined"
          value={resellPrice}
          onChange={(e) => setResellPrice(e.target.value)}
          autoComplete="off"
          error={!!resellPriceError}
          helperText={resellPriceError}
          className={styles.resellPrice}
        />
        {props.allowAmount && (
          <TextField
            inputProps={isMobile ? { pattern: '[0-9]*', type: 'number', inputmode: 'decimal' } : {}}
            label={t('playerView.amountToBuyInput')}
            variant="outlined"
            value={amountToBuy}
            autoComplete="off"
            className={styles.amountToBuy}
            error={!!amountToBuyError}
            helperText={amountToBuyError}
            onChange={(e) => setAmountToBuy(e.target.value)}
          />
        )}
        {props.allowAdvertisement && (
          <TextField
            inputProps={isMobile ? { pattern: '[0-9]*', type: 'number', inputmode: 'decimal' } : {}}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <span style={{ color: '#fff' }}>€</span>
                </InputAdornment>
              ),
            }}
            label={t('playerView.advertisementInput')}
            variant="outlined"
            value={advertisement}
            className={styles.advertisementInput}
            autoComplete="off"
            error={!!advertisementError}
            helperText={advertisementError}
            onChange={(e) => setAdvertisement(e.target.value)}
          />
        )}

        <Button onClick={onSend} variant="contained" className={styles.sendGameData} disabled={loading}>
          {loading ? <CircularProgress size={25} /> : t(answerButton)}
        </Button>
        <div className={styles.changeLang}>
          <ChangeLanguage />
        </div>
        <Dialog open={newWeekAlert && !props.joiningPhase}>
          <div className={styles.newWeekAlert}>
            <p style={{ marginTop: '0' }}>{t('playerView.newWeekAlert', { week: week })}</p>
            <Button onClick={() => setNewWeekAlert(false)} variant="contained" style={{ marginRight: '1rem' }}>
              {t('admin.confirmButton')}
            </Button>
          </div>
        </Dialog>
      </div>
    </Container>
  );
}

export default PlayerView;
