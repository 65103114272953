/*const Constants = {
  url: 'http://localhost:5000',
  ws: 'ws://localhost:5000',
  timeout: 3000,
};*/
const Constants = {
  url: 'https://api.ja.ee',
  ws: 'wss://api.ja.ee',
  timeout: 3000,
};
/*
const Constants = {
    url: "https://api.scdp.studio",
    ws: "wss://api.scdp.studio",
    timeout: 3000,
}*/
export default Constants;
