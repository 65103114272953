import { GameProps } from '../../Home/Play';
import HostView from './HostView/HostView';

import { HostData } from './HostView/HostView';
import PlayerView, { PlayerData } from './PlayerView/PlayerView';
import EndScreen, { EndScreenData } from './End/EndScreen';

interface GameState {
  screen: 'player' | 'host';
  data: HostData | PlayerData | EndScreenData;
  name: string;
}

function SupplyDemandGame(props: GameProps) {
  const gameState = props.gameState as GameState;
  if (props.gameState === undefined) {
    return <div>Sumting Wong</div>;
  }
  if (gameState.screen === 'host') {
    const data = gameState.data as HostData;
    return (
      <>
        <HostView
            {...data}
            send={props.send}
            joinToken={props.joinToken}
            showProjector={props.showProjector}
            setShowProjector={props.setShowProjector}
            showOnProjector={props.showOnProjector}
            code={props.gameCode}
        />
      </>
    );
  }
  if (gameState.screen === 'player') {
    const data = gameState.data as PlayerData;
    return (
      <>
        <PlayerView send={props.send} joinToken={props.joinToken} {...data} joiningPhase={props.joiningPhase}
                    gameState={props.gameState}/>
      </>
    );
  }
  if (gameState.screen === 'gameEnd') {
    const data = gameState.data as EndScreenData;
    return (
      <EndScreen
          code={props.gameCode}
          send={props.send}
          joinToken={props.joinToken}
          showProjector={props.showProjector}
          setShowProjector={props.setShowProjector}
          showOnProjector={props.showOnProjector}
          name={props.gameState.name}
          {...data}
      />
    );
  }
  return <>err</>;
}

export default SupplyDemandGame;
