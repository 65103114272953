import React, { ReactNode, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import styles from './ErrorMessage.module.scss';
import RedCloseButton from '../assets/RedCloseButton.svg';

const errors = {
  default: {
    title: 'errorModal.defaultTitle',
    message: 'errorModal.defaultMessage',
  },
  connectionLost: {
    title: 'errorModal.connectionLostTitle',
    message: 'errorModal.connectionLostMessage',
  },
  new_connection_error: {
    title: 'errorModal.newConnectionTitle',
    message: 'errorModal.newConnectionMessage',
  },
};

interface errorMessage {
  error: keyof typeof errors;
  isOpen: boolean;
}

export interface errorMessageContextType {
  sendErrorMessage(error?: keyof typeof errors): void;
}

export const errorMessageContext = React.createContext<errorMessageContextType>({
  sendErrorMessage: (error) => {
    //console.error('uninitialized error message');
  },
});

interface Props {
  children: ReactNode;
}

export const ErrorMessageContextProvider = (props: Props) => {
  const { t } = useTranslation();

  const [state, setState] = useState<errorMessage>({
    error: 'default',
    isOpen: false,
  });
  const sendErrorMessage = (error: keyof typeof errors = 'default') => {
    setState({ error, isOpen: true });
  };
  return (
    <errorMessageContext.Provider value={{ sendErrorMessage }}>
      <Dialog open={state.isOpen} onClose={() => setState({ ...state, isOpen: false })}>
        <div className={styles.modal}>
          <img src={RedCloseButton} className={styles.closeButton} onClick={() => setState({ ...state, isOpen: false })} />
          <p className={styles.errorMessageTitle}>{t(errors[state.error].title)}</p>
          <p className={styles.errorMessage}>{t(errors[state.error].message)}</p>
        </div>
      </Dialog>
      {props.children}
    </errorMessageContext.Provider>
  );
};
