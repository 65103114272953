import React, { useState, useEffect } from 'react';
import { Button, Container } from '@material-ui/core';
import { useNavigate } from '@reach/router';
import styles from './EndScreenMobile.module.scss';
import FinancialReport from '../Reports/FinancialReport';
import CompetitionReport from '../Reports/CompetitionReport';
import { useTranslation } from 'react-i18next';
import { CompetitionPlayerType, PlayerHistoryInfo, PublicInfo } from '../PlayerView/PlayerView';
export interface Props {
  standing: number;
  history: Array<PlayerHistoryInfo & PublicInfo>;
  name: string;
  competeArr: Array<CompetitionPlayerType>;
}
function EndScreenMobile(props: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [financialModal, setFinancialModal] = useState(false);
  const [competitionReport, setCompetitionReport] = useState(false);
  const [suffix, setSuffix] = useState('th');
  const [groupText, setGroupText] = useState('endScreenPlayer.theRest');
  useEffect(() => {
    if (props.standing === 1) {
      setSuffix('st');
      setGroupText('endScreenPlayer.firstPlace');
    } else if (props.standing === 2) {
      setSuffix('nd');
      setGroupText('endScreenPlayer.secondPlace');
    } else if (props.standing === 3) {
      setSuffix('rd');
      setGroupText('endScreenPlayer.thirdPlace');
    } else if (props.standing <= 10) {
      setGroupText('endScreenPlayer.topTen');
    }
  }, []);
  return (
    <Container maxWidth="sm">
      <div className={styles.wrapper}>
        <div className={styles.endScreenPlayer}>
          <span style={{ textAlign: 'center' }}>{props.name}</span>
          <span className={styles.placementTitle}>
            {props.standing}
            {suffix}
          </span>
          <span className={styles.placementText} style={{ color: '#fff' }}>
            {t(groupText)}
          </span>
          <Button onClick={() => setFinancialModal(true)} variant="contained">
            {t('global.financialReport')}
          </Button>
          <Button onClick={() => setCompetitionReport(true)} style={{ margin: '1rem 0' }} variant="contained">
            {t('global.competitionReport')}
          </Button>
          <Button onClick={() => navigate('/')} variant="contained">
            {t('global.homeButton')}
          </Button>
          <FinancialReport history={props.history} isOpen={financialModal} setIsOpen={setFinancialModal} />
          <CompetitionReport
            competeArr={props.competeArr}
            history={props.history}
            isOpen={competitionReport}
            setIsOpen={setCompetitionReport}
          />
        </div>
      </div>
    </Container>
  );
}

export default EndScreenMobile;
