import {Button, CircularProgress, Container} from '@material-ui/core';
import { TextField, withStyles } from '@material-ui/core';
import styles from './MobileHome.module.scss';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import ChangeLanguage from '../../../lib/ChangeLanguage';

interface Props {
  onJoin: () => void;
  state: [string, (value: string) => void];
  error?: string;
  loading?: boolean;
}
function MobileHome(props: Props) {
  const [value, setValue] = props.state;
  const { t } = useTranslation();

  const handleOnJoin = (e: any) => {
    e.preventDefault();
    props.onJoin();
  };

  return (
    <Container maxWidth="sm">
      <div className={isMobile ? styles.mobileHomeWrapper : styles.desktopJoinWrapper}>
        <form onSubmit={handleOnJoin} className={styles.mobileHome}>
          <span className={styles.gameTitle}>{t('mobileHome.gameTitle')}</span>
          <TextField
            inputProps={isMobile ? { pattern: '[0-9]*', type: 'number', inputmode: 'decimal' } : {}}
            autoComplete="off"
            label={t('mobileHome.codeInput')}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            variant="outlined"
            className={styles.codeInput}
            error={!!props.error}
            helperText={props.error}
          />
          <Button
            variant="contained"
            className={styles.joinGameButton}
            size="large"
            type="submit"
            disabled={props.loading}
          >
            {props.loading ? <CircularProgress size={25} /> : t('mobileHome.joinGameButton')}
          </Button>
          <div className={styles.changeLang}>
            <ChangeLanguage />
          </div>
          
        </form>
      </div>
    </Container>
  );
}

export default MobileHome;
