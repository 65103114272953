import beginImage from '../../assets/beginImage_az.svg';

const en = {
  translations: {
    global: {
      financialReport: 'Maliyyə Hesabatı',
      competitionReport: 'Müsabiqə hesabatı',
      homeButton: 'Ev',
      cancelButton: 'Ləğv et',
      closeProjector: 'Projektoru bağla',
      openProjector: 'Projektoru aç',
      fullscreenButton: 'Tam ekrana daxil olmaq üçün F11 düyməsini basın.',
    },
    translations: {
      ee: '🇪🇪 Estonca',
      en: '🇬🇧 İngiliscə',
      az: '🇦🇿 Azərbaycanca',
      ru: '🇷🇺 Rusca',
      beginImage: beginImage,
    },
    email: {
      failedTitle: 'Bir şey səhv oldu',
      failedMessage: 'Zəhmət olmasa bir daha cəhd edinş',
      successTitle: 'Müvəffəqiyyət!',
      emailConfirmed: 'E-mailınız doğrulanmayıb!',
      userNotFound: 'Bu e-mailı olan istifadəçi tapılmadı.',
      alreadyVerified: 'Bu hesab artıq təstiqlənib.',
      passwordChanged: 'Şifrəniz uğurla dəyişdirildi.',
      passwordLabel: 'Yeni Şifrə',
      passwordLabelSecond: 'Yeni Şifrə Yenidən',
      confirmPassword: 'Yeni Şifrəni Təsdiq Elə',
      passwordNotMatching: 'Şifrələr uyğun olmalıdır.',
      emailLabel: 'Email',
      sendResetEmailButton: 'E-mailı yenidən təyin et',
    },
    landingPage: {
      landingTitle: 'MARKET PRO',
      introText:
        "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or webdesigns. The passage is attributed to an unknown typesetter in the 15th century who is thought to havescrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.",
      joinGameButton: 'join game',
      logInModalButton: 'log in',
      email: 'E-mail',
      username: 'İstifadəçi adı',
      logInUsernameError: 'Yanlış istifadəçi adı.',
      password: 'Parol',
      logInPasswordError: 'Yanlış parol.',
      rememberMeLabel: 'Məni xatırla',
      logInButton: 'Daxil ol',
      aboutUsButton: 'Bizim haqqımızda',
      registerButton: 'Qeydiyyatdan keç',
      registerEmailTaken: 'E-mail artıq istifadə olunur.',
      incorrectEmailFormat: 'Zəhmət olmasa düzgün bir e-mail daxil edin.',
      registerUsernameTaken: 'Bu ad artıq istifadə olunur.',
      registerCreateAccountButton: 'Hesab yarat',
      passwordConfirm: 'Şifrəni təsdiq edin',
      passwordsDoNotMatch: 'Şifrələr uyğun gəlmir.',
      nameTooShort: 'Zəhmət olmasa adınızı daxil edin.',
      passwordTooShort: 'Zəhmət olmasa daha uzun şifrə daxil edin.',
      disclaimer: 'Bu layihə Avropa Birliyi tərəfindən maliyyələşdirilir.',
    },
    errorModal: {
      defaultTitle: 'Oops!',
      defaultMessage: 'Bir şey səhv oldu.',
      newConnectionTitle: 'Bu hesab başka bir yerdən qoşulub.',
      newConnectionMessage: 'Yalnız bir qurğu qoşula bilər.',
      connectionLostTitle: 'Əlaqə kəsildi',
      connectionLostMessage: 'İnternet bağlantınızı yoxlayın.',
    },
    home: {
      welcomeMessage: 'Xoş qəlmisiniz, {{name}}!',
      newGameButton: 'Yeni oyun',
      joinGameButton: 'Qoşul',
      howToPlayButton: 'Necə oynamalı?',
      accountSettingsButton: 'Hesab tənzimləmələri',
      adminButton: 'admin',
      logOut: 'çıxış',
    },
    admin: {
      usersListTitle: 'İstifadəçilər',
      filterRole: 'hər',
      adminRole: 'admin',
      teacherRole: 'müəllima',
      searchLabel: 'Axtar',
      username: 'istifadəçi adı',
      email: 'email',
      roleLabel: 'rol',
      statusLabel: 'status',
      accountActive: 'aktiv',
      accountInactive: 'fəaliyyətsiz',
      confirmButton: 'təsdiq edin',
      editButton: 'redaktə edin',
      deleteButton: 'sil',
      deleteConfirmation: '{{username}} istifadəçini silmək istədiyinizə əminsiniz?',
      acceptAllChanges: 'Hamısını qəbul et',
      countryLabel: 'ölkə',
    },

    mobileHome: {
      gameTitle: 'MARKET PRO',
      codeInput: 'Oyun kodu',
      joinGameButton: 'qoşul',
      incorrectGameCode: 'Səhv kod.',
    },
    mobileNameScreen: {
      nameInput: 'Ad',
      nameTitle: 'Ləqəb',
      nameTakenError: 'Ad qötürülüb.',
      noNameError: 'Heç bir ad daxil edilməyib.',
      genericNameError: 'Yanlış daxiletmə.',
      joinGameButton: 'OK',
    },
    playerView: {
      weekTitle: 'Həftə {{week}}',
      nextWeek: 'Gələn həftəni gözləyin.',
      waitForGame: 'Oyunun başlamasını gözləyin.',
      financialReport: 'Maliyyə Hesabatı',
      resellPriceInput: 'Təkrar Satış Qiyməti',
      amountToBuyInput: 'Alış Məbləği',
      advertisementInput: 'Reklam',
      sendGameData: 'Təsdiq edin',
      inputError: 'Daxiletmə xətasi.',
      insufficientFundsError: 'Vəsait catmır.',
      tooAccurateError: 'Cavab 2 onluq nöqtəsi ilə məhdudlaşır.',
      changeAnswerButton: 'Qərar dəyişdirin',
      answerChanged: 'Qərar təsdiqləndi ✓',
      newWeekAlert: '{{week}}-cü həftə başladı.',
    },
    financialReport: {
      reportTitle: 'Maliyyə Hesabatı',
      week: 'həftə',
      wholesalePrice: 'Topdan Satış Qiyməti',
      wagesPercent: 'Maaşlar (%)',
      boardDecision: 'Kollegiya gərarları',
      price: 'Qiymət',
      amount: 'Məbləğ',
      advertisement: 'Reklam',
      stock: 'Stok',
      stockLastWeek: 'Əvvəlki stok',
      stockRefill: 'Stokun doldurulması',
      stockTotal: 'Ümumi stok',
      revenue: 'Gəlir',
      sellingPrice: 'Satış qiyməti',
      cashFlow: 'Məliyya axını',
      expenses: 'Xərclər',
      fixedCosts: 'Sabit xərclər',
      wages: 'Maaşlar ($)',
      restockCost: 'Stoku doldurma xərci',
      totalCost: 'Ümumi xərci',
      orders: 'Sifarişlər',
      totalOrders: 'Ümumi sifarişlər',
      unfulfilledOrders: 'Yerinə yetirilməmiş sifarişlər',
      profit: 'Gəlir',
      ranking: 'Sıra',
      cash: 'Nağd pul',
      leftoverstock: 'Qalmış stok',
      summary: 'Xülasə',
    },
    competitionReport: {
      week: 'həftə',
      competition: 'Müsabiqə hesabatı',
      economicStatus: 'İqtisadi vəziyyət',
      totalPlayers: 'Cəmi oyunçu',
      averagePrice: 'Orta qiymət',
      soldUnits: 'Satılmış ədəd',
      stock: 'Stok',
      unfulfilledOrders: 'Yerinə yetirilməmiş sifarişlər',
      advertisingVolume: 'Reklam həcmi',
      weekResults: 'Həftənin nəticələri',
      profit: 'Gəlir',
      sellingPrice: 'Qiymət',
      totalProfit: 'Ümumi gəlir',
    },
    teacherReport: {
      week: 'Oyunçular (həftə {{week}})',
      price: 'Qiymət',
      amount: 'Məbləğ',
      advertisement: 'Reklam',
      stockLastWeek: 'Əvvəlki stok',
      sold: 'satılmış',
      revenue: 'Gəlir',
      fixedCosts: 'Sabit xərclər',
      wages: 'Maaşlar ($)',
      restockCost: 'Stoku doldurma xərci',
      totalCost: 'Ümumi xərci',
      totalOrders: 'Ümumi sifarişlər',
      unfulfilledOrders: 'Yerinə yetirilməmiş sifarişlər',
      leftoverstock: 'Qalmış stok',
      profit: 'Gəlir',
      placementThisWeek: 'Bu həftə yerləşdirmə',
      totalProfit: 'Ümumi gəlir',
      ranking: 'Sıra',
      cash: 'Nağd pul',
    },
    waitingScreen: {
      playerListTitle: 'OYUNÇULAR',
      closeProjector: 'Projektoru bağla',
      openProjector: 'Projektoru aç',
      beginGameButton: 'başla',
    },
    projectorWaitingScreen: {
      gameCodeLabel: 'oyun kodu',
    },
    hostView: {
      windowTooSmall: 'Tətbiqi istifadə etmək üçün pəncərinizi böyüdün.',
      eventsTitle: 'Hadisələr',
      playersTitle: 'Oyunçular',
      graphControlsTitle: 'Projektor qrafiki',
      balanceGraph: 'Balans',
      profitGraph: 'Gəlir',
      playerPricesGraph: 'Qiymətlər',
      averagePriceGraph: 'Orta qiymət',
      advertisingVolumeGraph: 'Reklam Həcmi',
      totalStockGraph: 'Ümumi Stok',
      unfulfilledOrdersGraph: 'Yerinə yetirilməmiş sifarişlər',
      closeProjector: 'Projektoru bağla',
      openProjector: 'Projektoru aç',
      nextWeekButton: 'Gələn həftə',
      endGameConfirmation: 'Oyundan çıxmaq istədiyinizə əminsiniz?',
      endGameButton: 'Oyunu bitir',
      kickButton: 'qov',
      allowAmount: 'İcazə verilən məbləğ',
      allowAdvertisement: 'Reklamı icazə ver',
      showNews: 'Xəbərləri göstər',
      hotWeatherEvent: 'İsti hava',
      coldWeatherEvent: 'Soyuq hava',
      cruiseShipEvent: 'kruiz gəmisi',
      newMovieEvent: 'yeni film',
      badTimesEvent: 'pis vaxtlar',
      teacherReport: 'muəllimə hesabı',
      customEvent: 'Xüsusi hadisə',
      demand: 'Tələb',
      advert: 'Reklam',
      wholesalePrice: 'Topdan Satış Qiyməti',
      fixedCosts: 'Sabit xərclər',
      wages: 'Maaşlar',
      reset: 'yenidən',
    },
    projector: {
      tweetUsername: 'Biznes Analitik',
      tweetTag: '@marketanalyst',
      hotWeatherEventNews: 'Gələn həftə isti hava şəraiti gözlənilir.',
      coldWeatherEventNews: 'Gələn həftə yağış yağacağı gözlənilir.',
      cruiseShipEventNews: 'Eyni zamanda bir neçə kruiz gəmisi gəmi limana çatır.',
      newMovieEventNews: 'Televiziyada çox tanımış bir serial nümayiş olunmağa başladı.',
      badTimesEventNews:
        'Ev sahibi kirayə haqqını artırdı və topdansatıcı da məhsul başına 0,5 avro olmaqla məhsulun qiymətini artırdı.',
    },
    endScreen: {
      gameOver: 'Oyun bitdi',
      leaderboardView: 'Lider lövhəsi',
      graphView: 'qraflar',
    },
    endScreenPlayer: {
      firstPlace: 'Təbriklər!👑🎉',
      secondPlace: 'Təbriklər!🥈🥳',
      thirdPlace: 'Təbriklər!🥉👔',
      topTen: 'Böyük liqadasan!💰📈',
      theRest: 'Narahat olma, qələn dəfə daha yaxşı uğurlar!💪',
    },
  },
};

export default en;
