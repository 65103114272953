import React, { useEffect, useContext, useState } from 'react';
import { ProjectorProps, WSProps } from '../../../Home/Play';
import { Round } from '../HostView/HostView';
import { CompetitionPlayerType, PlayerHistoryInfo, PublicInfo } from '../PlayerView/PlayerView';
import { Button, CircularProgress, Tooltip } from '@material-ui/core';
import styles from './EndScreen.module.scss';
import { useNavigate } from '@reach/router';
import EndScreenPlayer from './EndScreenPlayer';
import Constants from '../../../../lib/Constants';
import { UserContext } from '../../../../Contexts/User';
import { useTranslation } from 'react-i18next';
import LineGraph from '../LineGraph';
import { roundNumber } from '../../../../lib/helpers';
import CompetitionReport from '../Reports/CompetitionReport';
export interface EndScreenData {
  competeArr: Array<CompetitionPlayerType>;
  history: Array<Round>;
  isHost: boolean;
}

export interface Props extends EndScreenData, WSProps, ProjectorProps {
  code: string;
  name: string;
}

const graphFuncArr = [
  {
    name: 'hostView.balanceGraph',
    graphPlayerFunction: (p: PlayerHistoryInfo) => p.endBalance,
  },
  {
    name: 'hostView.profitGraph',
    graphPlayerFunction: (p: PlayerHistoryInfo) => p.profit,
  },
  {
    name: 'hostView.playerPricesGraph',
    graphPlayerFunction: (p: PlayerHistoryInfo) => p.price,
  },
  {
    name: 'hostView.averagePriceGraph',
    graphRoundFunction: (p: Round) => p.info.averagePrice,
  },
  {
    name: 'hostView.advertisingVolumeGraph',
    graphRoundFunction: (p: Round) => p.info.totalAdvert,
  },
  {
    name: 'hostView.totalStockGraph',
    graphRoundFunction: (p: Round) => p.info.totalStock,
  },
  {
    name: 'hostView.unfulfilledOrdersGraph',
    graphRoundFunction: (p: Round) => p.info.totalUnfilledOrders,
  },
];

function EndScreen(props: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [graphView, setGraphView] = useState(false);
  const [competitionModal, setCompetitionModal] = useState(false);
  const [publicHistory, setPublicHistory] = useState<Array<PublicInfo>>([]);
  const [competeArr, setCompeteArr] = useState<Array<CompetitionPlayerType>>([]);
  const [loading, setLoading] = useState(false);
  const [graphSetting, setGraphSetting] = useState(0);
  const graphName = graphFuncArr[graphSetting].name;
  let standings: Array<PlayerHistoryInfo & { name: string }> = [];
  const lastRound = props.history[props.history.length - 1];
  lastRound.players.forEach((val, key) => {
    standings.push({ ...val, name: key });
  });
  standings.sort((a, b) => a.ranking - b.ranking);

  let userStanding = -1;
  for (let i = 0; i < standings.length; i++) if (standings[i].name === props.name) userStanding = i + 1;

  let top3 = standings.slice(0, 3);

  let tops = ['', '', ''];
  let topsEndBalance = [-1, -1, -1];
  for (let i = 0; i < top3.length; i++) {
    tops[i] = top3[i].name;
    //@ts-ignore
    if (tops[i]) topsEndBalance[i] = lastRound.players.get(tops[i]).endBalance;
  }
  useEffect(() => {
    let updatedArr: Array<CompetitionPlayerType> = [];
    props.history[0].players.forEach((player, playerName) => {
      let temp: CompetitionPlayerType['values'] = [];
      props.history.forEach((round) => {
        const p = round.players.get(playerName);
        if (p === undefined) {
          temp.push({
            price: 0,
            profit: 0,
            endBalance: 0,
          });
        } else {
          temp.push({
            price: p.price,
            profit: p.profit,
            endBalance: p.endBalance,
          });
        }
      });
      updatedArr.push({
        name: playerName,
        values: temp,
      });
    });
    setCompeteArr(updatedArr);
  }, [props.history.length]);

  useEffect(() => {
    if (props.showProjector) {
      props.showOnProjector(projectorRender);
    }
  }, [props.showProjector, graphView, graphSetting]);

  const formattedPlayerData = (user: string) => {
    let formattedHistory: Array<PlayerHistoryInfo & PublicInfo> = [];
    for (let i = 0; i < props.history.length; i++) {
      const p = props.history[i].players.get(user);
      if (p == undefined) break;
      formattedHistory.push({
        ...props.history[i].info,
        ...p,
      });
    }
    return formattedHistory;
  };
  const handleEndGame = async () => {
    const displayLoading = setTimeout(() => {
      setLoading(true);
    }, 800);
    const resp = await fetch(`${Constants.url}/games/endGame?code=${props.code}&token=${user.token}`);
    const body = await resp.json();
    ////console.log(body);
    if (resp.status === 200) {
      navigate('/');
    } else {
    }
    clearTimeout(displayLoading);
    setLoading(false);
  };
  const onOpenCompetitionModal = () => {
    let formattedHistory: Array<PublicInfo> = [];
    for (let i = 0; i < props.history.length; i++) {
      formattedHistory.push({ ...props.history[i].info });
    }
    setPublicHistory(formattedHistory);
    setCompetitionModal(true);
  };
  let render = <></>;
  let projectorRender = <></>;
  if (graphView) {
    projectorRender = (
      <div className={styles.projectorWrapper}>
        <span className={styles.graphTitle}>{t(graphName)}</span>
        <div className={styles.graphProjector}>
          <LineGraph
            graphPlayerFunction={graphFuncArr[graphSetting].graphPlayerFunction}
            graphRoundFunction={graphFuncArr[graphSetting].graphRoundFunction}
            history={props.history}
          />
        </div>
      </div>
    );
  } else {
    projectorRender = (
      <div className={styles.endScreenModule}>
        <span className={styles.gameOver}>{t('endScreen.gameOver')}</span>
        <div className={styles.top3}>
          <span className={styles.winner}>{tops[0]}</span>
          <div className={styles.gold}>
            {tops[0] && <span className={styles.money}>€{roundNumber(topsEndBalance[0] - 1000, 2)}</span>}
          </div>
          <span className={styles.second}>{tops[1]}</span>
          <div className={styles.silver}>
          {tops[1] && <span className={styles.money}>€{roundNumber(topsEndBalance[1] - 1000, 2)}</span>}

          </div>
          <span className={styles.third}>{tops[2]}</span>
          <div className={styles.bronze}>
          {tops[2] && <span className={styles.money}>€{roundNumber(topsEndBalance[2] - 1000, 2)}</span>}
            
          </div>
        </div>
      </div>
    );
  }
  if (props.isHost) {
    render = (
      <div className={styles.endScreenModule}>
        <span className={styles.gameOver}>{t('endScreen.gameOver')}</span>
        {!graphView && (
          <div className={styles.top3}>
            <span className={styles.winner}>{tops[0]}</span>
            <div className={styles.gold}>
              {tops[0] && <span className={styles.money}>€{roundNumber(topsEndBalance[0] - 1000, 2)}</span>}
            </div>
            <span className={styles.second}>{tops[1]}</span>
            <div className={styles.silver}>
              {tops[1] && <span className={styles.money}>€{roundNumber(topsEndBalance[1] - 1000, 2)}</span>}
            </div>
            <span className={styles.third}>{tops[2]}</span>
            <div className={styles.bronze}>
              {tops[2] && <span className={styles.money}>€{roundNumber(topsEndBalance[2] - 1000, 2)}</span>}
            </div>
          </div>
        )}
        {graphView && (
          <div className={styles.graphHost}>
            <LineGraph
              graphPlayerFunction={graphFuncArr[graphSetting].graphPlayerFunction}
              graphRoundFunction={graphFuncArr[graphSetting].graphRoundFunction}
              history={props.history}
            />
          </div>
        )}
        <div className={styles.buttons}>
          <Button onClick={() => handleEndGame()} variant="contained" disabled={loading}>
            {loading ? <CircularProgress size={25} /> : t('global.homeButton')}
          </Button>
          <Tooltip placement="top-start" title={<span>{t('global.fullscreenButton')}</span>}>
            <Button
              onClick={() => props.setShowProjector(!props.showProjector)}
              style={{ margin: '0 1rem' }}
              variant="outlined"
              color="primary"
            >
              {props.showProjector ? t('global.closeProjector') : t('hostView.openProjector')}
            </Button>
          </Tooltip>
          <Button onClick={() => onOpenCompetitionModal()} variant="contained" style={{ marginRight: '1rem' }}>
            {t('global.competitionReport')}
          </Button>
          <CompetitionReport
            isOpen={competitionModal}
            setIsOpen={setCompetitionModal}
            history={publicHistory}
            competeArr={competeArr}
          />
          <Button onClick={() => setGraphView(!graphView)} variant="contained">
            {graphView ? t('endScreen.leaderboardView') : t('endScreen.graphView')}
          </Button>
        </div>
        <div className={styles.graphControls}>
          {graphView &&
            graphFuncArr.map((e, i) => (
              <Button
                variant="contained"
                style={{
                  margin: '0 1rem 1rem 0',
                  backgroundColor: graphSetting === i ? '#1A8AD4' : undefined,
                }}
                onClick={() => setGraphSetting(i)}
              >
                {t(e.name)}
              </Button>
            ))}
        </div>
      </div>
    );
  } else {
    render = (
      <EndScreenPlayer
        competeArr={competeArr}
        standing={userStanding}
        history={formattedPlayerData(props.name)}
        name={props.name}
      />
    );
  }

  return render;
}

export default EndScreen;
