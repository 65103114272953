import React, { useContext, useEffect, useState } from 'react';
import styles from '../Home/LandingPage.module.scss';
import { Button, CircularProgress, Dialog, IconButton, MenuItem, Select, TextField } from '@material-ui/core';
import RedCloseButton from '../../assets/RedCloseButton.svg';
import Constants from '../../lib/Constants';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../Contexts/User';

interface Props {
  isOpen: boolean;
  onChange: (val: boolean) => void;
}

function RegisterModal(props: Props) {
  const { t } = useTranslation();
  const { login } = useContext(UserContext);

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [country, setCountry] = useState('ee');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordConfirmError, setPasswordConfirmError] = useState('');
  const [loading, setLoading] = useState(false);

  const onValidate = () => {
    setNameError('');
    setEmailError('');
    setPasswordError('');
    setPasswordConfirmError('');
    if (name.length < 1) {
      setNameError('landingPage.nameTooShort');
      return true;
    }
    if (email.length < 2 || !email.includes('@')) {
      setEmailError('landingPage.incorrectEmailFormat');
      return true;
    }
    if (password.length < 4) {
      setPasswordError('landingPage.passwordTooShort');
      return true;
    }
    if (password !== passwordConfirm) {
      setPasswordError('landingPage.passwordsDoNotMatch');
      return true;
    }
    return false;
  };

  const onCreate = async (e: any) => {
    e.preventDefault();
    if (onValidate()) return;
    const displayLoading = setTimeout(() => {
      setLoading(true);
    }, 800);

    const resp = await fetch(`${Constants.url}/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        password: password,
        username: name,
        role: 'teacher',
        country: country,
      }),
    });
    const data = await resp.json();
    if (resp.status !== 200) {
      //console.log(data);
      if (data.nameTakenMessage) setNameError(`landingPage.registerUsernameTaken`);
      if (data.emailTakenMessage) setEmailError('landingPage.registerEmailTaken');
    } else {
      props.onChange(false);
      login(data.email, password, true);
    }
    clearTimeout(displayLoading);
    setLoading(false);
  };
  const handleSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setCountry(event.target.value as string);
  };
  /*    const onLogInModal = async (open: boolean) => {
            if (open && !params.logInOpen) {
                navigate(location.pathname + '?logInOpen=true');
            } else if (params.logInOpen) {
                navigate(location.pathname);
            }
        };
        useEffect(() => {
            if (lastOpen !== logInModal) {
                onLogInModal(logInModal);
                setLastOpen(logInModal);
            } else {
                setLogInModal(!!params.logInOpen);
                setLastOpen(!!params.logInOpen);
            }
        }, [logInModal, params.logInOpen]);*/

  return (
    <Dialog
      open={props.isOpen}
      onClose={() => props.onChange(false)}
      aria-labelledby="register modal"
      aria-describedby="register modal"
    >
      <div className={styles.modalWrapper}>
        <IconButton className={styles.closeButtonWrapper}>
          <img src={RedCloseButton} onClick={() => props.onChange(false)} className={styles.closeButton}/>
        </IconButton>

        <form onSubmit={onCreate} className={styles.modal}>
          <TextField
            label={t('landingPage.email')}
            variant="outlined"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              if (emailError) setEmailError('');
            }}
            error={!!emailError}
            helperText={t(emailError)}
          />
          <TextField
            label={t('landingPage.username')}
            variant="outlined"
            value={name}
            style={{ margin: '1rem 0' }}
            onChange={(e) => {
              setName(e.target.value);
              if (nameError) setNameError('');
            }}
            error={!!nameError}
            helperText={t(nameError)}
          />
          <Select value={country} onChange={handleSelectChange} id="countrySelect" labelId="language-select-label">
            <MenuItem value="en">{t('translations.en')}</MenuItem>
            <MenuItem value="ee">{t('translations.ee')}</MenuItem>
            <MenuItem value="az">{t('translations.az')}</MenuItem>
            <MenuItem value="ru">{t('translations.ru')}</MenuItem>
          </Select>
          <TextField
            label={t('landingPage.password')}
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!passwordError}
            helperText={t(passwordError)}
            style={{ marginTop: '1rem' }}
          />
          <TextField
            label={t('landingPage.passwordConfirm')}
            type="password"
            variant="outlined"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            error={!!passwordConfirmError}
            helperText={t(passwordConfirmError)}
            style={{ marginTop: '1rem' }}
          />

          <Button variant="contained" type="submit" style={{ marginTop: '1rem' }} disabled={loading}>
            {loading ? <CircularProgress size={25} /> : t('landingPage.registerCreateAccountButton')}
          </Button>
        </form>
      </div>
    </Dialog>
  );
}

export default RegisterModal;
