import {useState, useEffect, useRef} from 'react';
import {useLocation, useNavigate} from '@reach/router';
import { useQueryParams } from '../../../../lib/UseQueryParams';
import { Dialog, Button, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import styles from './FinancialReport.module.scss';
import { PublicInfo, PlayerHistoryInfo } from '../PlayerView/PlayerView';
import { roundNumber } from '../../../../lib/helpers';
import { useTranslation } from 'react-i18next';
import RedCloseButton from '../../../../assets/RedCloseButton.svg';
import useDragScroll from '../../../../lib/UseDragScroll';

export interface Props {
  history: Array<PlayerHistoryInfo & PublicInfo>;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

function FinancialReport(props: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useQueryParams();
  const [lastOpen, setLastOpen] = useState(false);
  const openModal = props.isOpen;
  const setOpenModal = props.setIsOpen;

  useEffect(() => {
    if (lastOpen !== openModal) {
      onOpenModal(openModal);
      setLastOpen(openModal);
    } else {
      setOpenModal(!!params.financeOpen);
      setLastOpen(!!params.financeOpen);
    }
  }, [openModal, params.financeOpen]);

  const onOpenModal = async (open: boolean) => {
    //console.log(location.hostname, location.pathname, location.search);
    if (open && !params.financeOpen) {
      navigate(location.pathname + '?financeOpen=true');
    } else if (params.financeOpen) {
      navigate(location.pathname);
    }
  };
  const getData = (func: (data: PlayerHistoryInfo & PublicInfo, i: number) => number): JSX.Element[] => {
    return props.history.map((round, i) => {
      let s = func(round, i);
      s = roundNumber(s, 2);
      return <td>{s}</td>;
    });
  };
  const dragProps = useDragScroll<HTMLDivElement>();

  useEffect(() => {
    if (dragProps.ref.current) {
      dragProps.ref.current.scrollLeft = 100000;
    }
  }, [dragProps.ref.current === null])

  return (
      <>
        <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="financial report"
            aria-describedby="financial report"
            style={{height: window.innerHeight}}
        >
          <DialogTitle>
            <div className={styles.reportBar}>
              <p className={styles.reportTitle}>{t('financialReport.reportTitle')}</p>
              <IconButton >
                <img src={RedCloseButton} className={styles.closeButton} onClick={() => setOpenModal(false)}/>
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent dividers style={{padding: '0'}} {...dragProps}>
            <table>
              <tbody>
              <tr>
                <th>{t('financialReport.week')}</th>
                {getData((_, i) => i)}
              </tr>
              <tr>
                <th>{t('financialReport.wholesalePrice')}</th>
                {getData((data) => data.wholesalePrice)}
              </tr>
              <tr>
                <th>{t('financialReport.wagesPercent')}</th>
                {getData((data) => data.wages * 100)}
              </tr>
              <tr>
                <th className={styles.subtitle}>{t('financialReport.boardDecision')}</th>
              </tr>
              <tr>
                <th>{t('financialReport.sellingPrice')}</th>
                {getData((data) => data.price)}
              </tr>
              <tr>
                <th>{t('financialReport.amount')}</th>
                {getData((data) => data.amount)}
              </tr>
              <tr>
                <th>{t('financialReport.advertisement')}</th>
                {getData((data) => data.advertisement)}
              </tr>
              <tr>
                <th className={styles.subtitle}>{t('financialReport.stock')}</th>
              </tr>
              <tr>
                <th>{t('financialReport.stockLastWeek')}</th>
                {getData((data) => data.startStock)}
              </tr>
              <tr>
                <th>{t('financialReport.stockRefill')}</th>
                {getData((data) => data.amount)}
              </tr>
              <tr>
                <th>{t('financialReport.stockTotal')}</th>
                {getData((data) => data.amount + data.startStock)}
              </tr>
              <tr>
                <th className={styles.subtitle}>{t('financialReport.revenue')}</th>
              </tr>
              <tr>
                <th>{t('financialReport.sellingPrice')}</th>
                {getData((data) => data.price)}
              </tr>
              <tr>
                <th>{t('financialReport.amount')}</th>
                {getData((data) => data.sold)}
              </tr>
              <tr>
                <th>{t('financialReport.cashFlow')}</th>
                {getData((data) => data.sold * data.price)}
              </tr>
              <tr>
                <th className={styles.subtitle}>{t('financialReport.expenses')}</th>
              </tr>
              <tr>
                <th>{t('financialReport.fixedCosts')}</th>
                {getData((data) => data.fixedCosts)}
              </tr>
              <tr>
                <th>{t('financialReport.advertisement')}</th>
                {getData((data) => data.advertisement)}
              </tr>
              <tr>
                <th>{t('financialReport.wages')}</th>
                {getData((data) => data.sold * data.price * data.wages)}
              </tr>
              <tr>
                <th>{t('financialReport.restockCost')}</th>
                {getData((data) => data.amount * data.wholesalePrice)}
              </tr>
              <tr>
                <th>{t('financialReport.totalCost')}</th>
                {getData(
                    (data) =>
                        data.amount * data.wholesalePrice +
                        data.sold * data.price * data.wages +
                        data.advertisement +
                        data.fixedCosts
                )}
              </tr>
              <tr>
                <th className={styles.subtitle}>{t('financialReport.orders')}</th>
              </tr>
              <tr>
                <th>{t('financialReport.totalOrders')}</th>
                {getData((data) => data.totalOrders)}
              </tr>
              <tr>
                <th>{t('financialReport.unfulfilledOrders')}</th>
                {getData((data) => data.totalOrders - data.sold)}
              </tr>
              <tr>
                <th>{t('financialReport.leftoverstock')}</th>
                {getData((data) => data.amount + data.startStock - data.sold)}
              </tr>
              <tr>
                <th className={styles.subtitle}>{t('financialReport.summary')}</th>
              </tr>
              <tr>
                <th>{t('financialReport.profit')}</th>
                {getData((data) => data.profit)}
              </tr>
              <tr>
                <th>{t('financialReport.ranking')}</th>
                {getData((data) => data.ranking)}
              </tr>
              <tr>
                <th>{t('financialReport.cash')}</th>
                {getData((data) => data.endBalance)}
              </tr>
            </tbody>
          </table>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default FinancialReport;
