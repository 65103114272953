export function makeId(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

export function roundNumber(num: number, places: number) {
    return Math.round((num + Number.EPSILON) * Math.pow(10, places)) / Math.pow(10, places);
}


export function reviver(key: string, value: any) {
    if (typeof value === "object" && value !== null) {
        if (value.dataType === "Map") {
            return new Map(value.value);
        }
    }
    return value;
};

export function replaceComma(s: string): string {
    for (let i = 0; i < s.length; i++) {
        if (s.charAt(i) === ',') {
            s = s.substr(0, i) + '.' + s.substr(i + 1);
        }
    }
    return s;
}
