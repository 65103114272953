import React, { useContext, useEffect, useState } from 'react';

import { RouteComponentProps, useNavigate } from '@reach/router';
import { UserContext } from '../../Contexts/User';
import Constants from '../../lib/Constants';
import SupplyDemandGame from '../Games/SupplyDemand/SupplyDemandGame';
import WaitingScreen, { WaitingScreenData } from './WaitingScreen';
import NewWindow from 'react-new-window';
import { CircularProgress } from '@material-ui/core';
import NameScreen from './Mobile/MobileNameScreen/NameScreen';
import { useQueryParams } from '../../lib/UseQueryParams';
import { makeId } from '../../lib/helpers';
import { ConnectionStatus, useWebSocket, wsResponse } from '../../lib/useWebSocket';
import MobileHome from './Mobile/MobileHome';
import { useTranslation } from 'react-i18next';
import { errorMessageContext } from '../../Contexts/ErrorMessage';

interface Props extends RouteComponentProps {
  code: string;
}

export interface WSProps {
  send(data: any): Promise<wsResponse>;

  joinToken: string;
}

export interface ProjectorProps {
  showProjector: boolean;

  setShowProjector(open: boolean): void;

  showOnProjector: (data: JSX.Element) => void;
}

export interface GameProps extends WSProps, ProjectorProps, PlayState {
  gameState: any;
  gameCode: string;
}

export interface PlayState {
  joiningPhase?: boolean;
  gameState?: WaitingScreenData | any;
  waiting?: boolean;
  isHost?: boolean;
}

const Play = (props: Props) => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const params = useQueryParams();
  const { t } = useTranslation();

  const { sendErrorMessage } = useContext(errorMessageContext);

  /*//console.log(`gamecode: ${props.code}`);*/

  const [cooldown, setCooldown] = useState(false);

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [showName, setShowName] = useState(true);

  const [nameTestCode, setNameTestCode] = useState('');
  const [showCode, setShowCode] = useState(true);
  const [codeValue, setCodeValue] = useState(props.code);
  const [codeError, setCodeError] = useState('');

  const [activeType, setActiveType] = useState('');
  const [joinToken, setJoinToken] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [nameError, setNameError] = useState('');

  const [onProjector, setOnProjector] = useState(<div>empty</div>);
  const [showProjector, setShowProjector] = useState(false);
  const [playState, setPlayState] = useState<PlayState>();

  const onMessage = async (data: any) => {
    //console.log('onMessage:', data);
    setPlayState(data);

    return 'good';
  };
  const onDisconnect = async (data: string) => {
    let o: any = {};

    //setErrorMessage("error: "+ data);

    try {
      o = JSON.parse(data);
    } catch (e) {
      //console.error(e);
      sendErrorMessage('connectionLost');
      setShowCode(true);
    }
    //console.error(data);

    if (o.m === 'invalid token') {
      setJoinToken('');
      setShowName(true);
    } else if (o.m === 'game_not_found') {
      setCodeError(t('mobileHome.incorrectGameCode'));
      setShowCode(true);
      setName('');
    } else if (o.m === 'kicked') {
      setShowName(true);
      setName('');
    } else if (o.m === 'new_connection_error') {
      sendErrorMessage('new_connection_error');
      setShowCode(true);
      setNameTestCode('');
    } else {
      //setErrorMessage('error: ' + data);
      setShowCode(true);
    }
  };

  const { wsSend, wsConnect, status } = useWebSocket(
    `${Constants.ws}/games/play?code=${props.code}&joinToken=${joinToken}`,
    onMessage,
    onDisconnect
  );
  //console.log('status,', status);

  useEffect(() => {
    if (
      !cooldown &&
      joinToken &&
      !showCode &&
      !showName &&
      (status === ConnectionStatus.Disconnected || status === ConnectionStatus.Errored)
    ) {
      //if (!cooldown) {
      //console.log('reconnecting');
      wsConnect();

      if (status === ConnectionStatus.Errored) {
        setCooldown(true);
        setTimeout(() => setCooldown(false), 500);
      }
    }
    ////console.log("ws Connetct2 ", joinToken,showName,showCode,status);
  }, [joinToken, showName, showCode, status, cooldown]);

  const onCheckCode = async () => {
    const displayLoading = setTimeout(() => {
      setLoading(true);
    }, 50);
    const resp = await fetch(`${Constants.url}/games/check?code=${props.code}`);
    const body = await resp.json();
    if (resp.status === 200) {
      ////console.log("game type set: " + body.type);
      setActiveType(body.type);
      const temp = localStorage.getItem('joinToken');
      if (temp) {
        setJoinToken(temp);
        setShowName(false);
      }
      setShowCode(false);
    } else {
      setCodeError(t('mobileHome.incorrectGameCode'));
    }
    clearTimeout(displayLoading);
    setLoading(false);
  };

  useEffect(() => {
    if (props.code) {
      onCheckCode();
    }
  }, [props.code]);

  const onCheckName = async (testing?: boolean) => {
    const displayLoading = setTimeout(() => {
      setLoading(true);
    }, 800);
    let temp = '';
    if (user.loggedIn) {
      temp = `&token=${user.token}`;
    }
    const resp = await fetch(`${Constants.url}/games/join?name=${name}&code=${props.code}${temp}`);
    const body = await resp.json();
    //console.log(body);
    if (resp.status === 200) {
      setJoinToken(body.joinToken);
      localStorage.setItem('joinToken', body.joinToken);
      if (showName) {
        setShowName(false);
      }
    } else {
      if (body.m === 'no_name') {
        if (testing) {
          setShowName(true);
        } else {
          setNameError(t('mobileNameScreen.noNameError'));
        }
      } else if (body.m === 'name_taken') setNameError(t('mobileNameScreen.nameTakenError'));
      else {
        setNameError(t('mobileNameScreen.genericNameError'));
      }
    }
    clearTimeout(displayLoading);
    setLoading(false);
  };

  useEffect(() => {
    if (user.loggedIn && !showCode && showName) {
      if (nameTestCode !== props.code) {
        onCheckName(true);
        setNameTestCode(props.code);
      }
      //console.log('checking name');
    }
    if (params.random) {
      const randName = makeId(6);
      setName(randName);
      setShowName(false);
    }
  }, [user.loggedIn, showCode, showName]);

  const joinGame = () => {
    onCheckName();
    //wsConnect();
  };

  const onSetCode = () => {
    if (!codeValue) {
      setCodeError(t('mobileHome.incorrectGameCode'));
    }
    if (props.code === codeValue) {
      onCheckCode();
      //setErrorMessage("checking code");
    } else {
      navigate(`/play/${codeValue}`, { replace: true });
    }
  };

  let render = <div>???</div>;
  if (showCode) {
    render = (
      <>
        <MobileHome onJoin={onSetCode} state={[codeValue, setCodeValue]} error={codeError} loading={loading} />
        {errorMessage}
      </>
    );
  } else if (errorMessage) {
    render = <div>{errorMessage}</div>;
  } else if (showName) {
    render = <NameScreen state={[name, setName]} joinGame={joinGame} nameError={nameError} loading={loading} />;
  } else if (playState === undefined || status === ConnectionStatus.Connecting) {
    ////console.log("playState:", playState);
    render = (
      <div>
        <CircularProgress size={25} />
      </div>
    );
  } else if (status === ConnectionStatus.Disconnected) {
    return <div>connection losst</div>;
  } else if (playState.joiningPhase && playState.isHost) {
    const waitingData = playState.gameState as WaitingScreenData;
    render = (
      <div>
        <WaitingScreen
          send={wsSend}
          joinToken={joinToken}
          {...waitingData}
          showProjector={showProjector}
          setShowProjector={setShowProjector}
          showOnProjector={setOnProjector}
          gameCode={props.code}
        />
      </div>
    );
  } else if (activeType === 'SupplyDemand') {
    render = (
      <div>
        <SupplyDemandGame
          gameState={playState.gameState}
          send={wsSend}
          joinToken={joinToken}
          showProjector={showProjector}
          setShowProjector={setShowProjector}
          showOnProjector={setOnProjector}
          joiningPhase={playState.joiningPhase}
          gameCode={props.code}
        />
      </div>
    );
  }
  return (
    <div>
      {showProjector && (
        <NewWindow
          onUnload={() => setShowProjector(false)}
          features={{ width: window.innerWidth, height: window.innerHeight }}
        >
          {onProjector}
        </NewWindow>
      )}
      {render}
    </div>
  );
};

export default Play;
