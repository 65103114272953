import React, {ChangeEvent} from 'react';
import styles from './NameScreen.module.scss';
import { Button, CircularProgress, Container } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

interface Props {
  state: [string, (name: string) => void];
  loading: boolean;
  joinGame: () => void;
  nameError?: string;
}

function MobileNameScreen(props: Props) {
  const {t} = useTranslation();
  const [name, setName] = props.state;

  const handleJoinGame = (e: any) => {
    e.preventDefault();
    props.joinGame();
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (e.target.value.length > 16) return;
    setName(e.target.value);
  }

  return (
    <Container maxWidth="sm">
      <div className={isMobile ? styles.mobileNameScreenWrapper : styles.desktopNameScreen}>
        <form className={styles.mobileNameScreen} onSubmit={handleJoinGame}>
          <span className={styles.nameTitle}>{t('mobileNameScreen.nameTitle')}</span>
          <TextField
            className={styles.nameInput}
            autoComplete="off"
            label={t('mobileNameScreen.nameInput')}
            value={name}
            error={!!props.nameError}
            helperText={props.nameError ? props.nameError : ''}
            onChange={handleChange}
            variant="outlined"
          />
          <Button
            variant="contained"
            className={styles.confirmNameButton}
            size="large"
            type="submit"
            disabled={props.loading}
          >
            {props.loading ? <CircularProgress size={25} /> : t('mobileNameScreen.joinGameButton')}
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default MobileNameScreen;
