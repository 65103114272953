import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { UserContext } from '../../Contexts/User';
import UserCard from './UserCard';
import RequireLogin from '../Login/RequireLogin';
import Constants from '../../lib/Constants';
import styles from './Admin.module.scss';
import { MenuItem, Select, TextField, InputAdornment, Button, InputLabel, FormControl } from '@material-ui/core';
import SearchIcon from '../../assets/SearchIcon.svg';
import { useTranslation } from 'react-i18next';

interface Props extends RouteComponentProps {}

export interface User {
  id?: string;
  username: string;
  email: string;
  country: string;
  role: string;
  status: boolean;
}
const Admin = RequireLogin((props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [users, setUsers] = useState<Array<User>>([]);
  const [search, setSearch] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [onAcceptArr, setOnAcceptArr] = useState<Array<(() => void) | undefined>>([]);

  const updateUsers = async () => {
    const resp = await fetch(`${Constants.url}/users?token=${user.token}`);
    const body = await resp.json();
    if (resp.status === 200) {
      ////console.log(body);
      setUsers(body);
    } else {
     // //console.log(body);
    }
  };
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRoleFilter(event.target.value as string);
  };
  useEffect(() => {
    updateUsers();
  }, []);

  const onAcceptAll = () => {
    for (let i = 0; i < onAcceptArr.length; i++) {
      if (onAcceptArr[i] !== undefined) {
        // @ts-ignore
        onAcceptArr[i]();
      }
      onAcceptArr[i] = undefined;
    }
  };

  const filterArray = () => {
    const filterF = (elem: User) => {
      if (!elem.email.includes(search) && !elem.username.includes(search)) return false;
      if (roleFilter !== '') {
        if (elem.role !== roleFilter) return false;
      }
      return true;
    };

    let ret = users.filter(filterF);
    return ret;
  };
  const displayArray = useMemo(filterArray, [users, search, roleFilter]);

  return (
    <div className={styles.admin}>
      <div className={styles.userCardWrapper}>
        <p className={styles.usersListTitle}>{t('admin.usersListTitle')}</p>
        <div className={styles.homeButtonWrapper}>
          <Button onClick={() => navigate('/')} variant="contained">
            {t('global.homeButton')}
          </Button>
        </div>

        <div className={styles.search} style={{ backgroundColor: '#19794B' }}>
          {onAcceptArr.some((elem) => elem !== undefined) && (
            <Button variant="contained" onClick={onAcceptAll} className={styles.acceptAllChanges}>
              {t('admin.acceptAllChanges')}
            </Button>
          )}
          <TextField
            style={{ margin: '0 1rem' }}
            label={t('admin.searchLabel')}
            name="search"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={SearchIcon} />
                </InputAdornment>
              ),
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <FormControl variant="filled" style={{ minWidth: '120px' }}>
            <InputLabel id="role-select-label">Role</InputLabel>
            <Select value={roleFilter} onChange={handleChange} id="roleSelect" labelId="role-select-label">
              <MenuItem value="">{t('admin.filterRole')}</MenuItem>
              <MenuItem value="admin">{t('admin.adminRole')}</MenuItem>
              <MenuItem value="teacher">{t('admin.teacherRole')}</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className={styles.labelsWrapper}>
          <span className={styles.usernameLabel}>{t('admin.username')}</span>
          <span className={styles.emailLabel}>{t('admin.email')}</span>
          <span className={styles.roleLabel}>{t('admin.roleLabel')}</span>
          <span className={styles.countryLabel}>{t('admin.countryLabel')}</span>
          <span className={styles.statusLabel}>{t('admin.statusLabel')}</span>
        </div>

        {displayArray.map((elem, i) => (
          <UserCard
            key={elem.email}
            user={elem}
            callback={() => {
              updateUsers();
            }}
            addAcceptAllFunc={(f: () => void) => {
              onAcceptArr[i] = f;
              setOnAcceptArr([...onAcceptArr]);
            }}
            onRemoveAcceptAllFunc={() => {
              onAcceptArr[i] = undefined;
              setOnAcceptArr([...onAcceptArr]);
            }}
          />
        ))}
      </div>
    </div>
  );
}, 'admin');

export default Admin;
