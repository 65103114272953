import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ChangeLanguage() {
  const { t, i18n } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(i18n.language); // en-US
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedValue(event.target.value as string);
    i18n.changeLanguage(event.target.value as string);
  };
  useEffect(() => {
    if (!['ee', 'en', 'az', 'ru'].some((lang) => lang === i18n.language)) {
      i18n.changeLanguage('en');
    }
  }, []);
  return (
    <Select value={i18n.language} onChange={handleChange} id="languageSelect" labelId="language-select-label">
      <MenuItem value="en">{t('translations.en')}</MenuItem>
      <MenuItem value="ee">{t('translations.ee')}</MenuItem>
      <MenuItem value="az">{t('translations.az')}</MenuItem>
      <MenuItem value="ru">{t('translations.ru')}</MenuItem>
    </Select>
  );
}
