import ReactHtmlParser from 'react-html-parser';
import { Round, RoundData } from './HostView';
import styles from './ProjectorScreen.module.scss';
import { PlayerHistoryInfo } from '../PlayerView/PlayerView';
import TweetLogo from '../../../../assets/TweetLogo.svg';
import test from '../../../../assets/azer.png';
import { useTranslation } from 'react-i18next';
import LineGraph from '../LineGraph'; 
import { useLocation } from '@reach/router';


let addZero = (s: number) => {
  let ret = s.toString();
  if (ret.length <= 1) ret = '0' + ret;
  return ret;
};

export interface Props extends RoundData {
  code: string;
  graphTitle: string;
  showNews?: boolean;
  graphPlayerFunction?: (p: PlayerHistoryInfo) => number;
  graphRoundFunction?: (p: Round) => number;
  answeredCount: string;
}

function ProjectorScreen(props: Props) {
  let render = <></>;
  const { t, i18n } = useTranslation();
  const loc = useLocation();
  const tweetDate = () => {
    let d = new Date();
    return `${addZero(d.getHours())}:${addZero(d.getMinutes())} • ${addZero(d.getDate())}.${addZero(
      d.getMonth()
    )}.${addZero(d.getFullYear())}`;
  };
  if (props.showNews) {
    render = (
      <div className={styles.projector}>
        <span className={styles.codeOnProjector}>
          {props.code} - Week {props.history.length} News - {props.answeredCount}
        </span>
        {props.history.length === 1 ? (
          <img src={loc.origin+t('translations.beginImage')} alt="" style={{ height: '100%' }} />
        ) : (
          <>
            {props.news.map((newsItem) => (
              <div className={styles.tweet}>
                <img src={loc.origin+TweetLogo} height="64px" className={styles.tweetLogo} />
                <span className={styles.tweetUsername}>{t('projector.tweetUsername')}</span>
                <span className={styles.tweetTag}>{t('projector.tweetTag')}</span>
                <span className={styles.news}>{t(`${ReactHtmlParser(newsItem)}`)}</span>
                <span className={styles.tweetDate}>{tweetDate()}</span>
              </div>
            ))}
          </>
        )}
      </div>
    );
  } else {
    render = (
      <div className={styles.projector}>
        <span className={styles.codeOnProjector}>
          {props.code} - {t(props.graphTitle)} Graph - {props.answeredCount}
        </span>
        <div className={styles.graphWrapper}>
          <LineGraph {...props} />
        </div>
      </div>
    );
  }

  return render;
}

export default ProjectorScreen;
