import { useContext, useEffect, useState } from 'react';
import styles from './UserCard.module.scss';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { UserContext } from '../../Contexts/User';
import { User } from './Admin';
import { MenuItem, Select, TextField, Button, Dialog, CircularProgress } from '@material-ui/core';
import Constants from '../../lib/Constants';
import { errorMessageContext } from '../../Contexts/ErrorMessage';
import { useTranslation } from 'react-i18next';

interface Props {
  user: User;
  callback: () => void;
  addAcceptAllFunc: (f: () => void) => void;
  onRemoveAcceptAllFunc: () => void;
}

function UserCard(props: Props) {
  const { user } = useContext(UserContext);
  const [state, setState] = useState({ ...props.user });
  const { sendErrorMessage } = useContext(errorMessageContext);
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setState({ ...props.user });
  }, [props.user]);

  const onEdit = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };
  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof state;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const handleDelete = async () => {
    const displayLoading = setTimeout(() => {
      setLoading(true);
    }, 800);
    const response = await fetch(`${Constants.url}/users/${state.id}?token=${user.token}`, {
      method: 'DELETE',
    });
    if (response.status !== 200) {
      sendErrorMessage();
    } else if (response.status === 200) setEditMode(false);
    props.callback();
    clearTimeout(displayLoading);
    setLoading(false);
  };

  const handleEdit = async () => {
    const displayLoading = setTimeout(() => {
      setLoading(true);
    }, 800);
    const response = await fetch(`${Constants.url}/users/${state.id}?token=${user.token}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: state.email,
        username: state.username,
        country: state.country,
        role: state.role,
        status: state.status,
      }),
    });
    if (response.status !== 200) {
      sendErrorMessage();
    } else if (response.status === 200) setEditMode(false);
    props.callback();
    clearTimeout(displayLoading);
    setLoading(false);
  };
  const handleCancel = () => {
    setEditMode(false);
    setDeleteMode(false);
    setState({ ...props.user });
  };

  useEffect(() => {
    if (editMode) {
      props.addAcceptAllFunc(() => {
        handleEdit();
      });
    } else {
      props.onRemoveAcceptAllFunc();
    }
  }, [editMode]);

  const onEditStatus = () => {
    if (!editMode) {
      setEditMode(true);
    }
    setState({ ...state, status: !state.status });
  };

  if (editMode) {
    return (
      <div className={styles.userCard}>
        <TextField
          label={t('admin.username')}
          autoComplete="off"
          name="username"
          variant="outlined"
          value={state.username}
          onChange={onEdit}
          className={styles.username}
        />
        <TextField
          label={t('admin.email')}
          name="email"
          autoComplete="off"
          variant="outlined"
          value={state.email}
          onChange={onEdit}
          className={styles.email}
        />

        <Select name="role" value={state.role} onChange={handleChange}>
          <MenuItem value="admin">{t('admin.adminRole')}</MenuItem>
          <MenuItem value="teacher">{t('admin.teacherRole')}</MenuItem>
        </Select>
        <Select
          name="country"
          value={state.country}
          onChange={handleChange}
          id="countrySelect"
          labelId="language-select-label"
        >
          <MenuItem value="en">{t('translations.en')}</MenuItem>
          <MenuItem value="ee">{t('translations.ee')}</MenuItem>
          <MenuItem value="az">{t('translations.az')}</MenuItem>
          <MenuItem value="ru">{t('translations.ru')}</MenuItem>
        </Select>
        <Button
          className={styles.statusButton}
          onClick={onEditStatus}
          variant="contained"
          style={{ backgroundColor: state.status ? '#008000' : '#F44336' }}
        >
          {state.status ? t('admin.accountActive') : t('admin.accountInactive')}
        </Button>
        <Button onClick={handleEdit} className={styles.editButton} variant="contained" disabled={loading}>
          {loading ? <CircularProgress size={25} /> : t('admin.confirmButton')}
        </Button>
        <Button onClick={handleCancel} className={styles.deleteButton} variant="outlined" color="primary">
          {t('global.cancelButton')}
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.userCard}>
      <span className={styles.username}>{state.username}</span>
      <span className={styles.email}>{state.email}</span>
      <span className={styles.role}>{state.role}</span>
      {state.country && <span className={styles.country}>{t(`translations.${state.country}`)}</span>}

      <Button
        className={styles.statusButton}
        onClick={onEditStatus}
        variant="contained"
        style={{ backgroundColor: state.status ? '#008000' : '#F44336' }}
      >
        {state.status ? t('admin.accountActive') : t('admin.accountInactive')}
      </Button>
      {!deleteMode && (
        <>
          <Button
            onClick={() => {
              setEditMode(true);
            }}
            className={styles.editButton}
            variant="contained"
          >
            {t('admin.editButton')}
          </Button>
          <Button
            onClick={(e) => setDeleteMode(true)}
            className={styles.deleteButton}
            variant="outlined"
            color="primary"
          >
            {t('admin.deleteButton')}
          </Button>
        </>
      )}
      {deleteMode && (
        <Dialog open={deleteMode}>
          <div className={styles.deleteConfirmation}>
            <p>{t('admin.deleteConfirmation', { username: state.username })}</p>
            <Button onClick={handleDelete} variant="contained" style={{ marginRight: '1rem' }} disabled={loading}>
              {loading ? <CircularProgress size={25} /> : t('admin.confirmButton')}
            </Button>
            <Button onClick={() => setDeleteMode(false)} variant="outlined" color="primary">
              {t('global.cancelButton')}
            </Button>
          </div>
        </Dialog>
      )}
    </div>
  );
}

export default UserCard;
