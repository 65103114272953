import React, { useContext, useEffect, useState } from 'react';
import { WSProps, ProjectorProps } from '../Home/Play';
import styles from './WaitingScreen.module.scss';
import Button from '@material-ui/core/Button';
import { stringify } from 'querystring';
import { StringifiableRecord } from 'query-string';
import { useTranslation } from 'react-i18next';
import Constants from '../../lib/Constants';
import { useNavigate } from '@reach/router';
import { UserContext } from '../../Contexts/User';
import { CircularProgress, Tooltip } from '@material-ui/core';

export interface WaitingScreenData {
  users: Array<{ name: string; role: string }>;
}

interface Props extends WSProps, ProjectorProps, WaitingScreenData {
  gameCode: string;
}

function WaitingScreen(props: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const onStart = () => {
    props.send({
      fName: 'beginGame',
    });
  };
  const onCancel = async () => {
    const displayLoading = setTimeout(() => {
      setLoading(true);
    }, 800);
    const resp = await fetch(`${Constants.url}/games/endGame?code=${props.gameCode}&token=${user.token}`);
    const body = await resp.json();
    //console.log(body);
    if (resp.status === 200) {
      navigate('/');
    } else {
    }
    clearTimeout(displayLoading);
    setLoading(false);
  };
  const onKick = (name: string, role: string) => {
    props.send({
      fName: 'kickPlayer',
      args: {
        name: name,
        role: role,
      },
    });
  };
  const onOpen = () => {
    props.setShowProjector(!props.showProjector);
  };
  //console.log(props);
  useEffect(() => {
    props.showOnProjector(
      <div className={styles.centeringWrapper}>
        <div className={styles.WaitingScreenProjector}>
          <span className={styles.gameCodeLabel}>{t('projectorWaitingScreen.gameCodeLabel')}</span>
          <p className={styles.gameCode}>{props.gameCode}</p>
          <p className={styles.connected}>{props.users.length - 1}</p>
          <div className={styles.firstNames}>
            {/* display first 8 names */}
            {props.users
              .filter((user) => user.role !== 'host')
              .reverse()
              .slice(0, 20)
              .map((user) => (
                <span key={user.name + 'player'} className={styles.playerNames}>
                  {user.name}
                </span>
              ))}
          </div>
        </div>
      </div>
    );
  }, [props.users]);
  return (
    <div className={styles.WaitingScreen}>
      <div className={styles.userModule}>
        <p className={styles.playerListTitle}>{t('waitingScreen.playerListTitle')}</p>
        <p className={styles.gameCodeHost}>{props.gameCode}</p>
        {props.users
          .filter((user) => user.role !== 'host')
          .map((user) => (
            <button key={user.name + 'player'} onClick={() => onKick(user.name, user.role)} className={styles.users}>
              <span>{user.name}</span>
            </button>
          ))}
      </div>
      <div className={styles.controls}>
        <Button onClick={onCancel} variant="outlined" color="primary" disabled={loading}>
          {loading ? <CircularProgress size={25} /> : t('global.cancelButton')}
        </Button>
        <Tooltip placement="top-start" title={<span>{t('global.fullscreenButton')}</span>}>
          <Button onClick={onOpen} variant="contained" style={{ margin: '0 1rem' }}>
            {props.showProjector ? t('global.closeProjector') : t('waitingScreen.openProjector')}
          </Button>
        </Tooltip>

        <Button onClick={onStart} variant="contained">
          {t('waitingScreen.beginGameButton')}
        </Button>
      </div>
    </div>
  );
}

export default WaitingScreen;
