import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate, RouteComponentProps } from '@reach/router';
import Constants from '../../../lib/Constants';
import { UserContext } from '../../../Contexts/User';
import styles from './Email.module.scss';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface Props extends RouteComponentProps {
  token?: string;
}

function ResetPassword(props: Props): JSX.Element {
  const [status, setStatus] = useState('email');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');

  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);

  const {verify} = useContext(UserContext);

  useEffect(() => {
    if (!props.token) return;
    const displayLoading = setTimeout(() => {
      setLoading(true);
    }, 800);
    const request = async () => {
      const resp = await fetch(`${Constants.url}/users/checkPasswordToken?token=${props.token}`);
      const body = await resp.json();
      //console.log(body);
      if (resp.status === 200) {
        setStatus('good');
      } else {
        setStatus(body.m);
      }
    };
    if (props.token) {
      request();
    } else {
      setStatus('no token');
    }
    clearTimeout(displayLoading);
    setLoading(false);
  }, [props.token]);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (password !== passwordAgain) {
      setError('email.passwordNotMatching');
      return;
    }
    const resp = await fetch(`${Constants.url}/users/resetPassword?token=${props.token}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password: password }),
    });
    const body = await resp.json();
    //console.log(body);
    if (resp.status === 200) {
      setStatus('changed');
      verify(body.token);
    } else {
      setStatus(body.m);
    }
  };
  const onForgotPassword: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (email.length < 2 || !email.includes("@")) {
      setEmailError('register.incorrectEmailFormat');
      return;
    }
    setEmailError("");
    const resp = await fetch(`${Constants.url}/users/sendResetEmail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email: email}),
    });
    const body = await resp.json();
    //console.log(body);
    if (resp.status === 200) {
      //EMAIL HAS BEEN SENT!!!
    } else {
      //console.error(body);
      //error occurred
    }
  };


  if (status === 'good') {
    return (
        <div className={styles.wrapper}>
          <form onSubmit={onSubmit} className={styles.component}>
            <TextField
                label={t('email.passwordLabel')}
                variant="outlined"
                value={password}
                type="password"
                className={styles.password}
                onChange={(e) => setPassword(e.target.value)}
                error={!!error}
                helperText={t(error)}
          />
          <TextField
              label={t('email.passwordLabelSecond')}
              variant="outlined"
              value={passwordAgain}
              type="password"
              className={styles.password}
              onChange={(e) => setPasswordAgain(e.target.value)}
              error={!!error}
              helperText={t(error)}
          />
          <Button variant="contained" type="submit" disabled={loading}>
            {loading ? <CircularProgress size={25} /> : t('email.confirmPassword')}
          </Button>
        </form>
      </div>
    );
  }
  if (status === 'changed') {
    return (
      <div className={styles.wrapper}>
        <div className={styles.component}>
          <span className={styles.title}>{t('email.successTitle')}</span>
          <span className={styles.message}>{t('email.passwordChanged')}</span>
          <div style={{placeSelf: 'end'}}>
            <Button onClick={() => navigate('/')} variant="contained">
              {t('global.homeButton')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
  if (status === "email") {
    return (<div className={styles.wrapper}>
      <div className={styles.component}>
        <div style={{placeSelf: 'end'}}>
          <Button onClick={() => navigate('/')} variant="contained">
            {t('global.homeButton')}
          </Button>
          <form onSubmit={onForgotPassword} className={styles.component}>
            <TextField
                label={t('email.emailLabel')}
                variant="outlined"
                value={email}
                type="text"
                className={""}
                onChange={(e) => setEmail(e.target.value)}
                error={!!emailError}
                helperText={t(emailError)}
            />
            <Button variant="contained" type="submit" disabled={loading}>
              {loading ? <CircularProgress size={25}/> : t('email.sendResetEmailButton')}
            </Button>
          </form>
        </div>
      </div>
    </div>);
  }
  return (
      <div className={styles.wrapper}>
        <div className={styles.component}>
          <span className={styles.title}>{t('email.failedTitle')}</span>
          <span className={styles.message}>{t('email.failedMessage')}</span>
          <div style={{placeSelf: 'end'}}>
            <Button onClick={() => navigate('/')} variant="contained">
              {t('global.homeButton')}
            </Button>
          </div>
    </div>
  </div>
  );
}

export default ResetPassword;
