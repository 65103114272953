import React, {useContext, useEffect, useState} from 'react';
import styles from "../Home/LandingPage.module.scss";
import {Button, Checkbox, CircularProgress, Dialog, FormControlLabel, IconButton, TextField} from "@material-ui/core";
import RedCloseButton from "../../assets/RedCloseButton.svg";
import {useTranslation} from "react-i18next";
import {UserContext} from "../../Contexts/User";
import {navigate} from "@reach/router";

interface Props {
    isOpen: boolean,
    onChange: (val: boolean) => void,
}

function RegisterModal(props: Props) {

    const {t} = useTranslation();
    const {login} = useContext(UserContext);

    const [username, setUsername] = useState('');
    const [usernameErrorText, setUsernameErrorText] = useState('');
    const [password, setPassword] = useState('');
    const [passwordErrorText, setPasswordErrorText] = useState('');
    const [remember, setRemember] = useState(true);
    const [loading, setLoading] = useState(false);


    const handleLogIn = async (e: any) => {
        e.preventDefault();
        const displayLoading = setTimeout(() => {
            setLoading(true);
        }, 800);
        const resp = await login(username, password, remember);
        if (resp.status !== 200) {
            //console.log(resp.data);
            if (!resp.data.username) {
                setPasswordErrorText('');
                setUsernameErrorText('landingPage.logInUsernameError');
            } else if (!resp.data.password) {
                setUsernameErrorText('');
                setPasswordErrorText('landingPage.logInPasswordError');
            }
        } else {
            props.onChange(false);
        }
        clearTimeout(displayLoading);
        setLoading(false);
    };

    const onForgotPassword = async () => {
        navigate("/reset")
    };

    return (
        <Dialog
            open={props.isOpen}
            onClose={() => props.onChange(false)}
            aria-labelledby="log in modal"
            aria-describedby="log in modal"
        >
            <div className={styles.modalWrapper}>
                <IconButton className={styles.closeButtonWrapper}>
                    <img src={RedCloseButton} className={styles.closeButton} onClick={() => props.onChange(false)}/>
                </IconButton>

                <form className={styles.modal} onSubmit={handleLogIn}>
                    <TextField
                        label={t('landingPage.username')}
                        variant="outlined"
                        value={username}
                        onChange={(e) => {
                            setUsername(e.target.value);
                            if (username === '') setUsernameErrorText('');
                        }}
                        error={!!usernameErrorText}
                        helperText={t(usernameErrorText)}
                    />
                    <TextField
                        label={t('landingPage.password')}
                        variant="outlined"
                        type="password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            if (password === '') setPasswordErrorText('');
                        }}
                        error={!!passwordErrorText}
                        helperText={t(passwordErrorText)}
                        style={{marginTop: '1rem'}}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox checked={remember} color="primary"
                                      onChange={(e) => setRemember(e.target.checked)}/>
                        }
                        label={t('landingPage.rememberMeLabel')}
                    />

                    <Button variant="contained" type="submit" disabled={loading}>
                        {loading ? <CircularProgress size={25}/> : t('landingPage.logInButton')}
                    </Button>
                </form>
                <Button onClick={onForgotPassword}>forgot password?</Button>
            </div>
        </Dialog>
    );
}

export default RegisterModal
