import { useState, useEffect, useRef } from 'react';

export default function useDragScroll<T extends HTMLElement>() {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [lastMousePosition, setLastMousePosition] = useState<number | null>(null);
  const ref = useRef<T>(null);

  const onMouseDown:React.MouseEventHandler<T>  = (e) => {
    setIsMouseDown(true);
    setLastMousePosition(e.clientX);
  }

  useEffect(() => {
    const onMouseUp = () => {
      setIsMouseDown(false);
      setLastMousePosition(null);
    }
    const onMouseMove = (e: MouseEvent) => {
      if (!isMouseDown) return;
      if (ref.current === null) return;
      if (lastMousePosition === null) return;
      ref.current.scrollLeft += lastMousePosition - e.clientX;
      setLastMousePosition(e.clientX);
    }

    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('mousemove', onMouseMove);
    return () => {
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [isMouseDown, lastMousePosition]);

  return {
    ref,
    onMouseDown,
  };
}
