import { useContext, useEffect, useState } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import Constants from '../../../lib/Constants';
import { UserContext } from '../../../Contexts/User';
import styles from './Email.module.scss';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

interface Props extends RouteComponentProps {
  token?: string;
}

function Confirm(props: Props) {
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('loading');
  const { t } = useTranslation();

  const { verify } = useContext(UserContext);

  useEffect(() => {
    const request = async () => {
      const resp = await fetch(`${Constants.url}/users/confirm?token=${props.token}`);
      const body = await resp.json();
      //console.log(body);
      if (resp.status === 200) {
        setTitle('email.successTitle');
        setMessage('email.emailConfirmed');
        verify(body.token);
      } else {
        setTitle('email.failedTitle');
        if (body.userNotFound) {
          setMessage('email.userNotFound');
        } else if (body.alreadyVerified) {
          setMessage('email.alreadyVerified');
        }
      }
    };
    if (props.token) {
      request();
    } else {
      setMessage('no token');
    }
  }, [props.token]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.component}>
        <span className={styles.title}>{t(title)}</span>
        <span className={styles.message}>{t(message)}</span>
        <div style={{ placeSelf: 'end' }}>
          <Button onClick={() => navigate('/')} variant="contained">
            {t('global.homeButton')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Confirm;
