import React, { useEffect, useState } from 'react';
import {
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Slider,
  DialogActions,
} from '@material-ui/core';
import FinancialReport from '../Reports/FinancialReport';
import CompetitionReport from '../Reports/CompetitionReport';
import TeacherReport from '../Reports/TeacherReport';
import { WSProps, ProjectorProps } from '../../../Home/Play';
import { CompetitionPlayerType, PlayerHistoryInfo, PublicInfo } from '../PlayerView/PlayerView';
import styles from './HostView.module.scss';
import Constants from '../../../../lib/Constants';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ProjectorScreen from './ProjectorScreen';
import { reviver } from '../../../../lib/helpers';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@reach/router';
import RedCloseButton from '../../../../assets/RedCloseButton.svg';

export interface Round {
  players: Map<string, PlayerHistoryInfo>;
  info: PublicInfo;
}

export interface HostData {
  current: Array<{ name: string; answered: boolean; connected: boolean }>;
  updateNumber: number;
}

export interface RoundData {
  history: Array<Round>;
  competeArr: Array<CompetitionPlayerType>;
  scenarioName: string;
  news: Array<string>;
  updateNr: number;
  allowAdvertisement: boolean;
  allowAmount: boolean;
}

interface ScenarioEvent {
  name: string;
  message: string;
  demand: number;
  advert: number;
  wholesalePrice: number;
  fixedCosts: number;
  wages: number;
}

const defaultEvent: ScenarioEvent = {
  name: 'default',
  message: '',
  demand: 0,
  advert: 0.15,
  wholesalePrice: 0.4,
  fixedCosts: 70,
  wages: 0.25,
};
const graphFuncArr = [
  {
    name: 'hostView.balanceGraph',
    graphPlayerFunction: (p: PlayerHistoryInfo) => p.endBalance,
  },
  {
    name: 'hostView.profitGraph',
    graphPlayerFunction: (p: PlayerHistoryInfo) => p.profit,
  },
  {
    name: 'hostView.playerPricesGraph',
    graphPlayerFunction: (p: PlayerHistoryInfo) => p.price,
  },
  {
    name: 'hostView.averagePriceGraph',
    graphRoundFunction: (p: Round) => p.info.averagePrice,
  },
  {
    name: 'hostView.advertisingVolumeGraph',
    graphRoundFunction: (p: Round) => p.info.totalAdvert,
  },
  {
    name: 'hostView.totalStockGraph',
    graphRoundFunction: (p: Round) => p.info.totalStock,
  },
  {
    name: 'hostView.unfulfilledOrdersGraph',
    graphRoundFunction: (p: Round) => p.info.totalUnfilledOrders,
  },
];

export interface Props extends HostData, WSProps, ProjectorProps {
  code: string;
}

function HostView(props: Props) {
  const navigate = useNavigate();

  const [graphSetting, setGraphSetting] = useState(0);
  const [events, setEvents] = useState<Array<ScenarioEvent>>([]);
  const [customEvent, setCustomEvent] = useState<ScenarioEvent>({
    name: '',
    message: '',
    demand: 0,
    advert: 0,
    wholesalePrice: defaultEvent.wholesalePrice,
    fixedCosts: 70,
    wages: 0,
  });
  const [selectedEvents, setSelectedEvents] = useState<Array<boolean>>([]);
  const [financialHistory, setFinancialHistory] = useState<Array<PlayerHistoryInfo & PublicInfo>>([]);
  const [publicHistory, setPublicHistory] = useState<Array<PublicInfo>>([]);
  const [teacherModal, setTeacherModal] = useState(false);
  const [financialModal, setFinancialModal] = useState(false);
  const [endGameModal, setEndGameModal] = useState(false);
  const [competitionModal, setCompetitionModal] = useState(false);
  const [customEventModal, setCustomEventModal] = useState(false);
  const [roundData, setRoundData] = useState<RoundData>({
    history: [],
    competeArr: [],
    scenarioName: '',
    news: [],
    updateNr: 0,
    allowAmount: true,
    allowAdvertisement: true,
  });
  const mobile = useMediaQuery('(max-width:960px)');
  const { t } = useTranslation();

  useEffect(() => {
    const getEvents = async () => {
      const resp = await fetch(`${Constants.url}/SupplyDemand/scenario/${roundData.scenarioName}/events`);
      const body = await resp.json();
      if (resp.status === 200) {
        setEvents(body);
        setSelectedEvents((body as any[]).map((_) => false));
      }
    };
    if (roundData.scenarioName) {
      getEvents();
    }
  }, [roundData.scenarioName]);

  useEffect(() => {
    const getRoundData = async () => {
      const resp = await fetch(`${Constants.url}/SupplyDemand/getRoundData?code=${props.code}`);
      const body = JSON.parse(await resp.text(), reviver);
      if (resp.status === 200) {
        setRoundData({ ...body, updateNr: roundData.updateNr + 1 });
        if (body.news.length > 0) {
          setGraphSetting(-1);
        } else {
          if (graphSetting === -1) {
            setGraphSetting(0);
          }
        }
      }
    };
    getRoundData();
  }, [props.updateNumber]);
  const handleAnsweredCount = () => {
    let c = 0;
    for (let i = 0; i < props.current.length; i++) {
      if (props.current[i].answered) c++;
    }
    return `${c} / ${props.current.length}`;
  };
  useEffect(() => {
    if (graphSetting === -1) {
      props.showOnProjector(
        <ProjectorScreen
          code={props.code}
          graphTitle={'news'}
          showNews={true}
          {...roundData}
          answeredCount={handleAnsweredCount()}
        />
      );
    } else {
      props.showOnProjector(
        <ProjectorScreen
          code={props.code}
          graphTitle={graphFuncArr[graphSetting].name}
          graphPlayerFunction={graphFuncArr[graphSetting].graphPlayerFunction}
          graphRoundFunction={graphFuncArr[graphSetting].graphRoundFunction}
          answeredCount={handleAnsweredCount()}
          {...roundData}
        />
      );
    }
  }, [roundData.updateNr, graphSetting]);

  useEffect(() => {
    // reset selected events & custom event for new round
    setSelectedEvents(events.map((_) => false));
    resetCustomEvent();
  }, [props.updateNumber, events.length]);

  const onNextRound = () => {
    let demand = 0;
    let advert = 0;
    let wholesalePrice = 0;
    let fixedCosts = 0;
    let wages = 0;
    let messages: Array<string> = [];

    let allEvents = customEvent.name ? [...events, customEvent] : events;
    let allSelectedEvents = customEvent.name ? [...selectedEvents, true] : selectedEvents;

    if (!allSelectedEvents.some((e) => e)) {
      demand = defaultEvent.demand;
      advert = defaultEvent.advert;
      wholesalePrice = defaultEvent.wholesalePrice;
      fixedCosts = defaultEvent.fixedCosts;
      wages = defaultEvent.wages;
      messages = [];
    }

    allSelectedEvents.map((sel, i) => {
      if (sel) {
        demand += allEvents[i].demand;
        advert += allEvents[i].advert;
        wholesalePrice = Math.max(wholesalePrice, allEvents[i].wholesalePrice);
        fixedCosts = Math.max(fixedCosts, allEvents[i].fixedCosts);
        wages = Math.max(wages, allEvents[i].wages);
        // don't push news for custom event
        if (i !== allSelectedEvents.length - 1) messages.push(`projector.${allEvents[i].name}News`);
      }
    });

    props.send({
      fName: 'nextRound',
      args: {
        demand: demand,
        advert: advert,
        wholesalePrice: wholesalePrice,
        fixedCosts: fixedCosts,
        wages: wages,
        messages: messages,
      },
    });
  };
  const onEndGame = () => {
    props.send({
      fName: 'endGame',
      args: {},
    });
  };
  const onKick = (name: string) => {
    props.send({
      fName: 'kickPlayer',
      args: {
        name: name,
        role: 'player',
      },
    });
  };
  const onOpen = () => {
    props.setShowProjector(!props.showProjector);
  };

  const onOpenCompetitionModal = () => {
    let formattedHistory: Array<PublicInfo> = [];
    for (let i = 0; i < roundData.history.length; i++) {
      formattedHistory.push({ ...roundData.history[i].info });
    }
    setPublicHistory(formattedHistory);
    setCompetitionModal(true);
  };

  const onOpenFinancialModal = (player: string, modal: string) => {
    let formattedHistory: Array<PlayerHistoryInfo & PublicInfo> = [];
    for (let i = 0; i < roundData.history.length; i++) {
      const p = roundData.history[i].players.get(player);
      if (p == undefined) break;
      formattedHistory.push({
        ...roundData.history[i].info,
        ...p,
      });
    }
    setFinancialHistory(formattedHistory);
    setFinancialModal(true);
  };
  const listUsers = () => {
    return props.current.map((user) => {
      let userStateStyle;

      if (user.answered) {
        userStateStyle = styles.userAnswered;
      } else if (!user.connected) {
        userStateStyle = styles.userDisconnected;
      }
      return (
        <Tooltip
          placement="top-start"
          key={user.name}
          title={
            <div className={styles.userTooltip}>
              <Button onClick={() => onKick(user.name)} style={{ marginRight: '1rem' }} variant="contained">
                {t('hostView.kickButton')}
              </Button>
              <Button onClick={() => onOpenFinancialModal(user.name, 'financial')} variant="contained">
                {t('global.financialReport')}
              </Button>
            </div>
          }
          interactive
        >
          <span className={userStateStyle}>{user.name}</span>
        </Tooltip>
      );
    });
  };

  const toggleAllowButton = (allowAdvertisement: boolean, allowAmount: boolean) => {
    //console.log('setting to: ', allowAdvertisement, allowAmount);
    props.send({
      fName: 'setAllow',
      args: {
        allowAdvertisement: allowAdvertisement,
        allowAmount: allowAmount,
      },
    });
  };

  const toggleSelected = (i: number) => {
    selectedEvents[i] = !selectedEvents[i];
    setSelectedEvents([...selectedEvents]);
  };

  const resetCustomEvent = () => {
    setCustomEvent({
      name: '',
      message: '',
      demand: defaultEvent.demand,
      advert: defaultEvent.advert,
      wholesalePrice: defaultEvent.wholesalePrice,
      fixedCosts: defaultEvent.fixedCosts,
      wages: defaultEvent.wages,
    });
  };

  const handleSliderChange = (name: string) => (event: any, value: number | number[]) => {
    let newValue = value as number;
    setCustomEvent({ ...customEvent, [name]: newValue, name: 'changed' });
  };

  if (mobile) {
    return <p>{t('hostView.windowTooSmall')}</p>;
  }
  return (
    <div className={styles.hostView}>
      
      <div className={styles.eventsModule}>
        <p className={styles.eventsTitle}>{t('hostView.eventsTitle')}</p>
        {events.map((ev, i) => (
          <Button
            onClick={() => {
              toggleSelected(i);
            }}
            variant="contained"
            style={{
              margin: '0.75rem 0',
              backgroundColor: selectedEvents[i] ? '#1A8AD4' : undefined,
            }}
          >
            {t('hostView.' + ev.name)}
          </Button>
        ))}
        <Button
          onClick={() => {
            setCustomEventModal(true);
          }}
          variant="contained"
          style={{
            margin: '0.75rem 0',
            backgroundColor: customEvent.name ? '#1A8AD4' : undefined,
          }}
        >
          {t('hostView.customEvent')}
        </Button>
        <Dialog
          open={customEventModal}
          onClose={() => setCustomEventModal(false)}
          aria-labelledby="custom event modal"
          aria-describedby="custom event modal"
        >
          <DialogTitle>
            <div className={styles.customEventBar}>
              <p className={styles.reportTitle}>{t('hostView.customEvent')}</p>
              <IconButton>
                <img src={RedCloseButton} alt="" className={styles.closeButton} onClick={() => setCustomEventModal(false)} />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent dividers>
            <p>{t(`hostView.demand`)}</p>
            <Slider
              value={customEvent.demand}
              aria-labelledby="demand-slider"
              valueLabelDisplay="auto"
              onChange={handleSliderChange('demand')}
              valueLabelFormat={(x) => (x > 0 ? `+${x * 100}%` : `${x * 100}%`)}
              step={0.05}
              min={-1}
              max={1}
            />

            <p>{t('hostView.advert')}</p>
            <Slider
              name="advert"
              value={customEvent.advert}
              aria-labelledby="demand-slider"
              valueLabelDisplay="auto"
              onChange={handleSliderChange('advert')}
              valueLabelFormat={(x) => (x > 0 ? `+${x * 100}%` : `${x * 100}%`)}
              step={0.05}
              min={-1}
              max={1}
            />
            <p>{t('hostView.wholesalePrice')}</p>
            <Slider
              name="wholesalePrice"
              value={customEvent.wholesalePrice}
              aria-labelledby="wholesalePrice-slider"
              valueLabelDisplay="auto"
              onChange={handleSliderChange('wholesalePrice')}
              valueLabelFormat={(x) => `${x}€`}
              step={0.1}
              min={0}
              max={10}
            />
            <p>{t('hostView.fixedCosts')}</p>
            <Slider
              name="fixedCosts"
              value={customEvent.fixedCosts}
              aria-labelledby="fixedCosts-slider"
              valueLabelDisplay="auto"
              onChange={handleSliderChange('fixedCosts')}
              valueLabelFormat={(x) => `${x}€`}
              step={5}
              min={0}
              max={500}
            />
            <p>{t('hostView.wages')}</p>
            <Slider
              name="wages"
              value={customEvent.wages}
              aria-labelledby="wages-slider"
              valueLabelDisplay="auto"
              onChange={handleSliderChange('wages')}
              valueLabelFormat={(x) => `${x * 100}%`}
              step={0.05}
              min={0}
              max={1}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={resetCustomEvent} variant="contained" color="primary" autoFocus>
              {t('hostView.reset')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div className={styles.userListModule}>
        <p className={styles.playersTitle}>{t('hostView.playersTitle')}</p>
        {listUsers()}
      </div>

      <FinancialReport isOpen={financialModal} setIsOpen={setFinancialModal} history={financialHistory} />
      <div className={styles.graphControls}>
        <p className={styles.graphControlsTitle}>{t('hostView.graphControlsTitle')}</p>
        {roundData.news.length > 0 && (
          <Button
            variant="contained"
            style={{
              margin: '0.75rem 0',
              backgroundColor: graphSetting === -1 ? '#1A8AD4' : undefined,
            }}
            onClick={() => setGraphSetting(-1)}
          >
            {t('hostView.showNews')}
          </Button>
        )}
        {graphFuncArr.map((e, i) => (
          <Button
            variant="contained"
            style={{
              margin: '0.75rem 0',
              backgroundColor: graphSetting === i ? '#1A8AD4' : undefined,
            }}
            onClick={() => setGraphSetting(i)}
          >
            {t(e.name)}
          </Button>
        ))}
      </div>
      <div className={styles.controlButtons}>
        <Button onClick={() => onOpenCompetitionModal()} variant="contained">
          {t('global.competitionReport')}
        </Button>
        <CompetitionReport
          isOpen={competitionModal}
          setIsOpen={setCompetitionModal}
          history={publicHistory}
          competeArr={roundData.competeArr}
        />
        {roundData.history.length > 0 && (
          <>
            <Button onClick={() => setTeacherModal(true)} variant="contained">
              {t('hostView.teacherReport')}
            </Button>
            <TeacherReport
              isOpen={teacherModal}
              setIsOpen={setTeacherModal}
              round={roundData.history[roundData.history.length - 1]}
              week={roundData.history.length}
            />
          </>
        )}
        <Button
          variant="contained"
          style={{
            backgroundColor: roundData.allowAmount ? '#1A8AD4' : undefined,
          }}
          onClick={() => toggleAllowButton(roundData.allowAdvertisement, !roundData.allowAmount)}
        >
          {t('hostView.allowAmount')}
        </Button>
        <Button
          variant="contained"
          style={{
            backgroundColor: roundData.allowAdvertisement ? '#1A8AD4' : undefined,
          }}
          onClick={() => toggleAllowButton(!roundData.allowAdvertisement, roundData.allowAmount)}
        >
          {t('hostView.allowAdvertisement')}
        </Button>
        <Button onClick={() => navigate('/')} variant="outlined" color="primary">
          {t('global.homeButton')}
        </Button>
        <Tooltip placement="top-start" title={<span>{t('global.fullscreenButton')}</span>}>
          <Button onClick={onOpen} variant="outlined" color="primary">
            {props.showProjector ? t('global.closeProjector') : t('hostView.openProjector')}
          </Button>
        </Tooltip>
        <Button onClick={() => setEndGameModal(true)} variant="outlined" color="primary">
          {t('hostView.endGameButton')}
        </Button>
        <Dialog open={endGameModal}>
          <div className={styles.deleteConfirmation}>
            <p style={{ marginTop: '0' }}>{t('hostView.endGameConfirmation')}</p>
            <Button onClick={onEndGame} variant="contained" >
              {t('hostView.endGameButton')}
            </Button>
            <Button onClick={() => setEndGameModal(false)} variant="outlined" color="primary">
              {t('global.cancelButton')}
            </Button>
          </div>
        </Dialog>
        <Button onClick={onNextRound} variant="contained">
          {t('hostView.nextWeekButton')}
        </Button>
        <span>{handleAnsweredCount()}</span>
      </div>
    </div>
  );
}

export default HostView;
