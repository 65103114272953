import beginImage from '../../assets/beginImage_ru.svg';


const en = {
  translations: {
    global: {
      financialReport: 'Финансовый отчет',
      competitionReport: 'Отчет о конкуренции',
      homeButton: 'В начало',
      cancelButton: 'Отмена',
      closeProjector: 'Закрыть проектор',
      openProjector: 'Открыть проектор',
      fullscreenButton: 'Нажмите F11, чтобы войти в полноэкранный режим.',
    },
    translations: {
      ee: '🇪🇪 Эсонский',
      en: '🇬🇧 Английский',
      az: '🇦🇿 Азербайджанский',
      ru: '🇷🇺 Русский',
      beginImage: beginImage,
    },
    email: {
      failedTitle: 'Что-то пошло не так',
      failedMessage: 'Попробуйте еще раз',
      successTitle: 'Успешно!',
      emailConfirmed: 'Ваш э-мэйл теперь подтвержден!',
      userNotFound: 'Пользователь с этим э-мэйлом не найден.',
      alreadyVerified: 'Эта учетная запись уже подтверждена.',
      passwordChanged: 'Ваш пароль был успешно изменен.',
      passwordLabel: 'Новый пароль',
      passwordLabelSecond: 'Еще раз новый пароль',
      confirmPassword: 'Подтвердите новый пароль',
      passwordNotMatching: 'Пароли должны совпадать.',
      emailLabel: 'Э-мэйл',
      sendResetEmailButton: 'Сбросить э-мэйл',
    },
    landingPage: {
      landingTitle: 'ГУРУ РЫНКА',
      introText:
        "Lorem ipsum, или «lipsum», как его иногда называют, - это фиктивный текст, используемый при разметке печатных, графических или веб-дизайнов. Этот отрывок приписывается неизвестному наборщику в 15 веке, который, как считается, скопировал части книги Цицерона De Finibus Bonorum et Malorum для использования в сборнике образцов шрифта.",
      joinGameButton: 'присоединиться к игре',
      logInModalButton: 'Войти',
      email: 'Э-мэйл',
      username: 'Имя пользователя',
      logInUsernameError: 'Неправильное имя пользователя.',
      password: 'Пароль',
      logInPasswordError: 'Неправильный пароль.',
      rememberMeLabel: 'Запомнить меня',
      logInButton: 'Войти',
      aboutUsButton: 'О нас',
      registerButton: 'Зарегистрироваться',
      registerEmailTaken: 'Э-мэйл уже используется.',
      incorrectEmailFormat: 'Введите корректный э-мэйл.',
      registerUsernameTaken: 'Имя уже используется.',
      registerCreateAccountButton: 'Создать учетную запись',
      passwordConfirm: 'Подтвердить пароль',
      passwordsDoNotMatch: 'Пароли не совпадают.',
      nameTooShort: 'Введите имя.',
      passwordTooShort: 'Введите более длинный пароль.',
      disclaimer: 'Этот проект финансируется Европейским Союзом.',
    },
    errorModal: {
      defaultTitle: 'Ой!',
      defaultMessage: 'Что-то пошло не так.',
      newConnectionTitle: 'Эта учетная запись подключена из другого места.',
      newConnectionMessage: 'Только одно устройство может быть подключено.',
      connectionLostTitle: 'Соединение потеряно',
      connectionLostMessage: 'Проверьте ваше интернет-соединение.',
    },
    home: {
      welcomeMessage: 'Добро пожаловать, {{name}}!',
      newGameButton: 'новая игра',
      joinGameButton: 'присоединиться',
      howToPlayButton: 'как играть?',
      accountSettingsButton: 'настройки аккаунта',
      adminButton: 'админ',
      logOut: 'выйти',
    },
    admin: {
      usersListTitle: 'Пользователи',
      filterRole: 'любая',
      adminRole: 'админ',
      teacherRole: 'учитель',
      searchLabel: 'Поиск',
      username: 'имя пользователя',
      email: 'э-мэйл',
      roleLabel: 'роль',
      statusLabel: 'статус',
      accountActive: 'активен',
      accountInactive: 'неактивен',
      confirmButton: 'подтвердить',
      editButton: 'изменить',
      deleteButton: 'удалить',
      deleteConfirmation: 'Вы уверены, что хотите удалить пользователя {{username}}?',
      acceptAllChanges: 'Принять все',
      countryLabel: 'страна',
    },

    mobileHome: {
      gameTitle: 'ГУРУ РЫНКА',
      codeInput: 'код игры',
      joinGameButton: 'присоединиться',
      incorrectGameCode: 'Неправильный код.',
    },
    mobileNameScreen: {
      nameInput: 'Имя',
      nameTitle: 'Ник',
      nameTakenError: 'Имя занято.',
      noNameError: 'Имя не введено.',
      genericNameError: 'Некорректный ввод.',
      joinGameButton: 'OK',
    },
    playerView: {
      weekTitle: 'Неделя {{week}}',
      nextWeek: 'Ждите следующей недели.',
      waitForGame: 'Подождите, пока игра начнется.',
      financialReport: 'Финансовый отчет',
      resellPriceInput: 'Цена продажи',
      amountToBuyInput: 'Купить',
      advertisementInput: 'Реклама',
      sendGameData: 'Подтвердить решения',
      inputError: 'Ошибка ввода.',
      insufficientFundsError: 'Недостаточно средств.',
      tooAccurateError: 'Ответ ограничен двумя знаками после запятой.',
      changeAnswerButton: 'Изменить решения',
      answerChanged: 'Решения приняты ✓',
      newWeekAlert: 'Неделя {{week}} началась.',
    },
    financialReport: {
      reportTitle: 'Финансовый отчет',
      week: 'неделя',
      wholesalePrice: 'Оптовая цена',
      wagesPercent: 'Зарплата (%)',
      boardDecision: 'Решения',
      price: 'Цена',
      amount: 'Количество',
      advertisement: 'Реклама',
      stock: 'Склад',
      stockLastWeek: 'было на складе',
      stockRefill: 'пополнение склада',
      stockTotal: 'всего для продажи',
      revenue: 'Доход',
      sellingPrice: 'Цена продажи',
      cashFlow: 'доход',
      expenses: 'Расходы',
      fixedCosts: 'Фиксированные расходы',
      wages: 'Зарплаты ($)',
      restockCost: 'Стоимость закупок',
      totalCost: 'Всего расходов',
      orders: 'Заказы',
      totalOrders: 'Всего заказов',
      unfulfilledOrders: 'Невыполненных заказов',
      profit: 'Прибыль',
      ranking: 'Место',
      cash: 'Наличность',
      leftoverstock: 'остаток на складе',
      summary: 'Итоги',
    },
    competitionReport: {
      week: 'неделя',
      competition: 'Отчет о конкуренции',
      economicStatus: 'инфо об экономике',
      totalPlayers: 'Всего игроков',
      averagePrice: 'Средняя цена',
      soldUnits: 'Продано штук',
      stock: 'Склад',
      unfulfilledOrders: 'невыполныеных заказов',
      advertisingVolume: 'объем рекламы',
      weekResults: 'Результкты недели',
      profit: 'Прибыль',
      sellingPrice: 'Цена',
      totalProfit: 'Накопленая прибыль',
    },
    teacherReport: {
      week: 'Игроки (неделя {{week}})',
      price: 'Цена',
      amount: 'Количество',
      advertisement: 'Реклама',
      stockLastWeek: 'склад в начале недели',
      sold: 'продано',
      revenue: 'Доход',
      fixedCosts: 'Фиксированные затараты',
      wages: 'Зарплаты ($)',
      restockCost: 'Затраты на закупки',
      totalCost: 'Всего расходов',
      totalOrders: 'Всего заказов',
      unfulfilledOrders: 'Невыполненых заказов',
      leftoverstock: 'Остаток на складе',
      profit: 'Прибыль',
      placementThisWeek: 'Место недели',
      totalProfit: 'Накопленная прибыль',
      ranking: 'место',
      cash: 'касса',
    },
    waitingScreen: {
      playerListTitle: 'ИГРОКИ',
      closeProjector: 'Закрыть проектор',
      openProjector: 'Открыть пректор',
      beginGameButton: 'начать',
    },
    projectorWaitingScreen: {
      gameCodeLabel: 'код игры',
    },
    hostView: {
      windowTooSmall: 'Чтобы использовать приложение, сделайте окно больше.',
      eventsTitle: 'События',
      playersTitle: 'Игроки',
      graphControlsTitle: 'Графики',
      balanceGraph: 'Касса',
      profitGraph: 'Прибыль',
      playerPricesGraph: 'Цены',
      averagePriceGraph: 'Средняя цена',
      advertisingVolumeGraph: 'Обем рекламы',
      totalStockGraph: 'Склад',
      unfulfilledOrdersGraph: 'Невыполненные заказы',
      closeProjector: 'Закрыть проектор',
      openProjector: 'Открыть пректор',
      nextWeekButton: 'следующая неделя',
      endGameConfirmation: 'Вы уверены, что хотите закончить игру?',
      endGameButton: 'закончить игру',
      kickButton: 'удалить',
      allowAmount: 'Разрешить количество',
      allowAdvertisement: 'Разрешить рекламу',
      showNews: 'Показать новости',
      hotWeatherEvent: 'Жаркая погода',
      coldWeatherEvent: 'Холодная погода',
      cruiseShipEvent: 'Круизные лайнеры',
      newMovieEvent: 'Новое кино',
      badTimesEvent: 'Сложные времена',
      teacherReport: 'Отчет учителя',
      customEvent: 'Настраиваемое событие',
      demand: 'Спрос',
      advert: 'Реклама',
      wholesalePrice: 'Оптовая цена',
      fixedCosts: 'Фиксированные затраты',
      wages: 'Зарплаты',
      reset: 'сброс настроек',
    },
    projector: {
      tweetUsername: 'Аналитик рынка',
      tweetTag: '@marketanalyst',
      hotWeatherEventNews: 'Прогноз погоды обещает на следующую неделю жаркую погоду.',
      coldWeatherEventNews: 'Прогноз погоды обещает на следующую неделю дождливую погоду.',
      cruiseShipEventNews: 'В порт вашего города пришло несколько круизных кораблей.',
      newMovieEventNews: 'На экраны вышел популярный сериал. В течение одной серии три рекламных паузы.',
      badTimesEventNews: 'Владелец повысил плату за склад и оптовый продавец увеличил цену на мороженное.',
    },
    endScreen: {
      gameOver: 'Игра окончена',
      leaderboardView: 'Таблица лидеров',
      graphView: 'графики',
    },
    endScreenPlayer: {
      firstPlace: 'Поздравления!👑🎉',
      secondPlace: 'Поздравления!🥈🥳',
      thirdPlace: 'Поздравления!🥉👔',
      topTen: 'В высшей лиге!!💰📈',
      theRest: 'Не переживай! В следующий раз повезет больше!💪',
    },
  },
};

export default en;
