import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useNavigate, useLocation } from '@reach/router';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import styles from './LandingPage.module.scss';
import {useTranslation} from 'react-i18next';
import LandingPageScheme from '../../assets/LandingPageScheme.svg';
import jaEstonia from '../../assets/estonia.png';
import euroflag from '../../assets/euroflag.jpg';
import pashaBank from '../../assets/pashaBank.svg';
import jaAzer from '../../assets/azer.png';
import RegisterModal from "../Login/RegisterModal";
import LoginModal from "../Login/LoginModal";

interface Props extends RouteComponentProps {
}

const LandingPage = (props: Props) => {
  const [logInModal, setLogInModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);

  const {t} = useTranslation();

  const navigate = useNavigate();

  const onJoin = () => {
    navigate(`/play`);
  };
  
  return (
    <div className={styles.landingPage}>
      <p className={styles.landingTitle}>{t('landingPage.landingTitle')}</p>
      <img src={LandingPageScheme} className={styles.scheme} width="100%" alt=""/>
      <div className={styles.contentWrapper}>
        <p className={styles.introText}>{t('landingPage.introText')}</p>
        <div className={styles.CTAWrapper}>
          <Button variant="contained" onClick={onJoin}>
            {t('landingPage.joinGameButton')}
          </Button>

          <Button onClick={() => setLogInModal(true)} variant="contained" style={{ margin: '0 1rem' }}>
            {t('landingPage.logInModalButton')}
          </Button>

          <Button
              variant="outlined"
              onClick={() => setRegisterModal(true)}
              style={{marginRight: '1rem'}}
              color="primary"
          >
            {t('landingPage.registerButton')}
          </Button>

          <LoginModal isOpen={logInModal} onChange={setLogInModal}/>
          <RegisterModal isOpen={registerModal} onChange={setRegisterModal}/>
        </div>
      </div>
      <div className={styles.logos}>
        <img src={jaEstonia} height="64" />
        <img src={jaAzer} height="64" />
        <div className={styles.euroWrapper}>
          <img src={euroflag} height="64" />
          <span className={styles.disclaimer}>{t('landingPage.disclaimer')}</span>
        </div>
        <img src={pashaBank} height="64" />
      </div>
    </div>
  );
};

export default LandingPage;
