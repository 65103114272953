import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from '@reach/router';
import { useQueryParams } from '../../../../lib/UseQueryParams';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import styles from './CompetitionReport.module.scss';
import { CompetitionPlayerType, PublicInfo } from '../PlayerView/PlayerView';
import { roundNumber } from '../../../../lib/helpers';
import { useTranslation } from 'react-i18next';
import RedCloseButton from '../../../../assets/RedCloseButton.svg';
import useDragScroll from '../../../../lib/UseDragScroll';

export interface Props {
  history: Array<PublicInfo>;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  competeArr: Array<CompetitionPlayerType>;
}

function CompetitionReport(props: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useQueryParams();
  const [lastOpen, setLastOpen] = useState(false);
  const openModal = props.isOpen;
  const setOpenModal = props.setIsOpen;

  useEffect(() => {
    if (lastOpen !== openModal) {
      onOpenModal(openModal);
      setLastOpen(openModal);
    } else {
      setOpenModal(!!params.compOpen);
      setLastOpen(!!params.compOpen);
    }
  }, [openModal, params.compOpen]);

  const onOpenModal = async (open: boolean) => {
    //console.log(location.hostname, location.pathname, location.search);
    if (open && !params.compOpen) {
      navigate(location.pathname + '?compOpen=true');
    } else if (params.compOpen) {
      navigate(location.pathname);
    }
  };

  const getData = (func: (data: PublicInfo, i: number) => number): JSX.Element[] => {
    return props.history.map((round, i) => {
      let s = func(round, i);
      if (typeof s === 'number') {
        s = roundNumber(s, 1);
      }
      return <td>{s}</td>;
    });
  };
  const dragProps = useDragScroll<HTMLDivElement>();
  useEffect(() => {
    if (dragProps.ref.current) {
      dragProps.ref.current.scrollLeft = 100000;
    }
  }, [dragProps.ref.current === null]);
  let lastWeek = props.history.length !== 0 ? props.history.length-1 : 0;
  return (
    <>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="financial report"
        aria-describedby="financial report"
        style={{ height: window.innerHeight }}
      >
        <DialogTitle>
          <div className={styles.reportBar}>
            <p className={styles.reportTitle}>{t('competitionReport.competition')}</p>
            <IconButton >
              <img src={RedCloseButton} className={styles.closeButton} onClick={() => setOpenModal(false)} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers style={{ padding: '0' }} {...dragProps}>
          <table>
            <tbody>
              <tr>
                <th>{t('competitionReport.week')}</th>
                {getData((_, i) => i)}
              </tr>
              <tr>
                <th className={styles.subtitle}>{t('competitionReport.economicStatus')}</th>
              </tr>
              <tr>
                <th>{t('competitionReport.totalPlayers')}</th>
                {getData((data) => data.totalPlayers)}
              </tr>
              <tr>
                <th>{t('competitionReport.averagePrice')}</th>
                {getData((data) => data.averagePrice)}
              </tr>
              <tr>
                <th>{t('competitionReport.soldUnits')}</th>
                {getData((data) => data.totalSold)}
              </tr>
              <tr>
                <th>{t('competitionReport.stock')}</th>
                {getData((data) => data.totalStock)}
              </tr>
              <tr>
                <th>{t('competitionReport.unfulfilledOrders')}</th>
                {getData((data) => data.totalUnfilledOrders)}
              </tr>
              <tr>
                <th>{t('competitionReport.advertisingVolume')}</th>
                {getData((data) => data.totalAdvert)}
              </tr>
              <tr>
                <th className={styles.subtitle}>{t('competitionReport.weekResults')}</th>
              </tr>
              {props.competeArr
                .sort(
                  (a, b) =>
                    b.values[lastWeek].endBalance - a.values[lastWeek].endBalance
                )
                .map((user, i) => (
                  <tr key={`${user.name}+${i}`}>
                    <th>
                      <span className={styles.subtitle}>{user.name}</span>
                      <br />
                      {t('competitionReport.profit')}
                      <br />
                      <span>{t('competitionReport.sellingPrice')}</span>
                      <br />
                      {t('competitionReport.totalProfit')}
                    </th>
                    {props.competeArr[i].values.map((round) => (
                      <td>
                        <br />
                        {roundNumber(round.profit, 2)}€
                        <br />
                        {roundNumber(round.price, 2)}€
                        <br />
                        {roundNumber(round.endBalance - 620, 2)}€
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CompetitionReport;
