import React, {ReactNode, useEffect, useState} from "react";
import Constants from "../lib/Constants";


export interface User {
    email: string,
    username: string,
    role: string,
    status: boolean,
    token: string,
    loggedIn: boolean,
    remember: boolean,
    loading: boolean,
}

export interface Result<T> {
    status: number,
    data: T,
}


export interface UserContextType {
    user: User,

    login(username: string, password: string, remember: boolean): Promise<Result<any>>,

    logout(): void,

    verify(token?: string): void,
}

const initState: User = {
    username: "",
    email: "",
    role: "",
    token: "",
    status: false,
    loggedIn: false,
    remember: false,
    loading: false,
};


export const UserContext = React.createContext<UserContextType>({
    user: initState,
    login: async (username, password, remember) => {
        return {status: 0, data: 0}
    },
    logout: () => {
    },
    verify: () => {
    }
});

interface Props {
    children: ReactNode
}


export const UserContextProvider = (props: Props) => {

    const setUser = (user: Partial<User>) => {
        state.user = {...state.user, ...user};
        setState({...state});
    };

    const verify = async (token = "", remember = false) => {
        if (token.length === 0) {
            token = state.user.token;
            remember = state.user.remember;
        }

        const resp = await fetch(Constants.url + '/users/verify?token=' + token);
        const body = await resp.json();

        if (resp.status === 200) {
            let b = {...body, token, remember, loggedIn: true};
            ////console.log("verify:", b);

            setUser({...body, token, remember, loggedIn: true, loading: false});
        } else {
            setUser({loading: false});
        }
    };

    const logout = () => {
        if (state.user.loggedIn) {
            setUser(initState);
            ////console.log("heere2");
        }
        localStorage.setItem('userToken', '');
        sessionStorage.setItem('userToken', '');
        localStorage.removeItem("joinToken");
        sessionStorage.removeItem("joinToken");
        ////console.log("heere", state.user, initState);
    };
    const login = async (username: string, password: string, remember: boolean = true) => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({username, password})
        };
        const resp = await fetch(Constants.url + '/users/login', requestOptions);
        const body = await resp.json();

        const result: Result<any> = {status: resp.status, data: body};

        if (resp.status === 200) {

            setUser({...body.user, token: body.token, remember, loggedIn: true, loading: false});
        }

        return result;
    };

    const [state, setState] = useState<UserContextType>({
        user: {...initState, loading: true},
        login,
        logout,
        verify
    });


    //init
    useEffect(() => {

        let token = localStorage.getItem('userToken');
        let remember = true;
        if (token == null || token.length < 10) {
            remember = false;
            token = sessionStorage.getItem('userToken');
        }

        ////console.log('saved token= ' + token);

        if (token != null && token.length > 10) {
            verify(token, remember);
        } else {
            setUser({loading: false});
        }
    }, []);

    useEffect(() => {
        if (state.user.token.length > 0) {
            ////console.log('saved ' + state.user.token);
            if (state.user.remember) {
                localStorage.setItem('userToken', state.user.token);
                sessionStorage.setItem('userToken', state.user.token);
            } else {
                localStorage.setItem('userToken', '');
                sessionStorage.setItem('userToken', state.user.token)
            }
        } else {
            localStorage.setItem('userToken', '');
            sessionStorage.setItem('userToken', '');
        }
        ////console.log(state.user);
    }, [state.user.token]);

    return (
        <UserContext.Provider value={state}>
            {props.children}
        </UserContext.Provider>
    )
};


