import React, {useEffect, useState} from 'react';
import {Round, RoundData} from './HostView/HostView';
import Chart from 'chart.js';
import {ChartData, Line} from 'react-chartjs-2';
import {PlayerHistoryInfo} from './PlayerView/PlayerView';
import {useTranslation} from 'react-i18next';

export interface Props {
    history: Array<Round>;
    graphPlayerFunction?: (p: PlayerHistoryInfo) => number;
    graphRoundFunction?: (p: Round) => number;
    showAll?: boolean;
}

function LineGraph(props: Props) {
    const {t} = useTranslation();

    const getColor = (hue: number) => {
        hue = hue - Math.floor(hue);
        let offsets = [0, 1 / 3, 2 / 3];
        let saturation = 1;
        let value = 1;

        let vals = offsets.map((off) => {
            let r = off + hue - Math.floor(off + hue);
            if (r < 1 / 6) {
                return 6 * r;
            }
            if (r < 1 / 2) {
                return 1;
            }
            if (r < 2 / 3) {
                return 4 - 6 * r;
            }
            return 0;
        });
        vals = vals.map((val) => Math.floor(255 * value * (val * saturation + (1 - saturation))));
        const t = (val: number) => {
            const s = val.toString(16);
            return s.length === 1 ? '0' + s : s;
        };
        return `#${t(vals[0])}${t(vals[1])}${t(vals[2])}`;
    };
    const getGraphData = (getData: (p: PlayerHistoryInfo) => number): ChartData<Chart.ChartData> => {
        let names: string[] = [];
        if (props.history.length > 0) {
            props.history[0].players.forEach((val, key) => {
                names.push(key);
            });
        }

        let dataArr: Chart.ChartDataSets[] = names.map((name, i) => {
            let t = props.history.map((round) => {
                const val = round.players.get(name);
                return val === undefined ? 0 : getData(val);
            });
            return {
                data: t,
                label: name,
                fill: false,
                borderColor: getColor(i / names.length),
                backgroundColor: getColor(i / names.length),
            };
        });

        ////console.log("data: ", dataArr);
        return {
            labels: props.history.map((_, i) => i),
            datasets: dataArr,
        };
    };

    let data: ChartData<Chart.ChartData> = {};

    if (props.graphPlayerFunction) {
        data = getGraphData(props.graphPlayerFunction);
    }
    if (props.graphRoundFunction !== undefined) {
        let vals: number[] = []
        props.history.forEach((r) => {
            // @ts-ignore
            vals.push(props.graphRoundFunction(r))
        })

        data = {
            labels: props.history.map((_, i) => i),
            datasets: [{
                data: vals,
                label: "",
                fill: false,
                borderColor: "#fff",
                backgroundColor: "#fff",
            }],
        };
    }

    return <Line
        data={data}
        options={{
            legend: {
                labels: {
                    fontColor: '#fff',
                },
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            fontSize: 20,
                            fontColor: '#fff',
                        },
                    },
                ],
                xAxes: [
                    {
                        ticks: {
                            fontSize: 20,
                            fontColor: '#fff',
                        },
                    },
                ],
            },
        }}
    />
}

export default LineGraph;
