import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useNavigate, Link } from '@reach/router';
import { UserContext } from '../../Contexts/User';
import Constants from '../../lib/Constants';
import Button from '@material-ui/core/Button';
import styles from './Home.module.scss';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MobileHome from './Mobile/MobileHome';
import LandingPage from './LandingPage';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import ChangeLanguage from '../../lib/ChangeLanguage';

interface Props extends RouteComponentProps {}

const Home = (props: Props) => {
  const { user } = useContext(UserContext);
  const [message, setMessage] = useState('');
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('SupplyDemand');
  const mobile = useMediaQuery('(max-width:960px)');

  const navigate = useNavigate();

  const { t } = useTranslation();
  const [activeGames, setActiveGames] = useState<Array<string>>([]);

  useEffect(() => {
    const updateActiveGames = async () => {
      const resp = await fetch(`${Constants.url}/games/status?token=${user.token}`);
      const body = await resp.json();
      //console.log('activeGames: ', body);
      if (resp.status === 200) {
        setActiveGames(body);
      }
    };
    if (user.loggedIn) {
      updateActiveGames();
    }
  }, [user.loggedIn]);

  const onJoin = () => {
    if (value.length === 0) {
      setMessage('enter code');
      return;
    }
    navigate(`/play/${value}`);
  };

  useEffect(() => {
    setValue(value.toUpperCase());
  }, [value]);
  const onCreate = async () => {
    const displayLoading = setTimeout(() => {
      setLoading(true);
    }, 800);
    const resp = await fetch(`${Constants.url}/games/create?type=${type}&token=${user.token}`);
    const body = await resp.json();
    //console.log(body);
    clearTimeout(displayLoading);
    setLoading(false);
    navigate(`/play/${body.code}`);
  };
  if (mobile) {
    return <MobileHome onJoin={onJoin} state={[value, setValue]} />;
  }
  if (user.loggedIn) {
    return (
      <div className={styles.homeWrapper}>
        <div className={styles.home}>
          <p className={styles.welcomeMessage}> {t('home.welcomeMessage', { name: user.username })}</p>
          <div className={styles.buttonsWrapper}>
            <Button onClick={onCreate} variant="contained" disabled={!user.status || loading}>
              {loading ? <CircularProgress size={25} /> : t('home.newGameButton')}
            </Button>
            <Button onClick={() => navigate(`/play`)} variant="outlined" color="primary">
              {t('home.joinGameButton')}
            </Button>
            {user.role === 'admin' && (
              <Button onClick={() => navigate(`/admin`)} variant="outlined" color="primary">
                {t('home.adminButton')}
              </Button>
            )}
            <ChangeLanguage />
            <Button onClick={() => navigate(`/logout`)} variant="outlined" color="primary">
                {t('home.logOut')}
              </Button>
          </div>

          {activeGames.length > 0 && (
            <div className={styles.activeGamesModule}>
              {activeGames.map((elem) => (
                <button
                  key={'gameButton-' + elem}
                  className={styles.activeGames}
                  onClick={() => navigate(`/play/${elem}`)}
                >
                  {elem}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
  return <LandingPage />;
};

export default Home;
