import { ENETRESET } from 'constants';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import azRes from './Languages/az';
import enRes from './Languages/en';
import eeRes from './Languages/ee';
import ruRes from './Languages/ru';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      az: azRes,
      en: enRes,
      ee: eeRes,
      ru: ruRes,
    },
    fallbackLng: 'en',
    debug: true,

    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: '.',

    interpolation: {
      escapeValue: true,
    },
  });

export default i18n;
