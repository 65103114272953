import React, { useContext, useEffect } from 'react';
import { Router, Link, RouteComponentProps, useNavigate } from '@reach/router';
import Home from './components/Home/Home';
import Play from './components/Home/Play';
import Admin from './components/Admin/Admin';
import { ThemeProvider } from '@material-ui/core';
import createMyTheme from './styles/theme';
import { UserContext } from './Contexts/User';
import './i18n/i18n';
import Confirm from "./components/Home/Email/Confirm";
import ResetPassword from "./components/Home/Email/ResetPassword";

const Redirecter = (props: RouteComponentProps) => {
  ////console.log('unknonw url');
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/');
  }, []);
  return <div>a</div>;
};
const Logoutter = (props: RouteComponentProps) => {
  ////console.log('unknonw url');
  const navigate = useNavigate();
  const { logout } = useContext(UserContext);
  useEffect(() => {
    logout();
    navigate('/');
  }, []);
  return <div>a</div>;
};
function App() {
  const theme = createMyTheme({});

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Router>
          <Home path="/"/>
          <Play path="/play/:code" code=""/>
          <Play path="/play" code=""/>
          <Admin path="/admin"/>
          <Logoutter path="/logout"/>
          <Confirm path="/confirm/:token" token=""/>
          <ResetPassword path="/reset/:token" token=""/>
          <ResetPassword path="/reset"/>
          <Redirecter default/>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
