import {useContext, useEffect, useRef, useState} from "react";
import Constants from "./Constants";
import {UserContext} from "../Contexts/User";


export interface wsResponse {
    status: string,
    data?: any,
}

interface Message {
    n: number,
    type: string,
    data: any,
}

export enum ConnectionStatus {
    Disconnected,
    Connecting,
    Connected,
    Errored,
}

export const useWebSocket = (url: string, onMessage: (data: any) => Promise<string>, onDisconnect: (reason: string) => any) => {

    const requestNr = useRef(1);
    const callbacks = useRef(new Map<number, (value: wsResponse) => void>());
    const ws = useRef<WebSocket>(null);

    const [status, setStatus] = useState(ConnectionStatus.Disconnected);


    const wsConnect = () => {
        setStatus(ConnectionStatus.Connecting);

        // @ts-ignore
        ws.current = new WebSocket(url);
        ws.current.onerror = (ev) => {
            //console.log("error : ", ev);
            wsDisconnect("error");
        };
        ws.current.onmessage = async (ev: MessageEvent) => {

            const reviver = (key: string, value: any) => {
                if (typeof value === "object" && value !== null) {
                    if (value.dataType === "Map") {
                        return new Map(value.value);
                    }
                }
                return value;
            };

            const body = JSON.parse(ev.data, reviver) as Message;
            if (body.type === "ping") {
                if (ws.current) ws.current.send(JSON.stringify({n: body.n, type: "pong", data: "good"}));
                return;
            }
            if (body.type === "pong") {
                if (callbacks.current.has(body.n)) {
                    try {
                        // @ts-ignore
                        callbacks.current.get(body.n)({status: body.data});
                        callbacks.current.delete(body.n);
                        //console.log("receieved", body.n);
                    } catch (e) {
                        //console.error(e);
                    }
                }
                return;
            }
            const resp = await onMessage(body.data);
            if (ws.current) ws.current.send(JSON.stringify({n: body.n, type: "pong", data: resp}));
        };
        ws.current.onopen = (ev: Event) => {
            ////console.log("onopen");
            setStatus(ConnectionStatus.Connected);
        };
        ws.current.onclose = (ev) => {
            onDisconnect(ev.reason);
            setStatus(ConnectionStatus.Disconnected);
            //console.log("disconnect: ", ev.reason);
        };
    };

    const wsSend = async (data: any, type: string = "call"): Promise<wsResponse> => {
        if (ws.current) {
            requestNr.current += 1;
            const nr = requestNr.current;
            ws.current.send(JSON.stringify({n: nr, type, data}));

            return new Promise(resolve => {
                callbacks.current.set(nr, resolve);
                setTimeout(() => {
                    if (callbacks.current.has(nr)) {
                        resolve({
                            status: "timeout",
                        })
                        //console.log("timed out");
                        wsDisconnect("timeout");
                    }
                }, Constants.timeout);
            })
        }
        return {
            status: "failed",
            data: "not connected"
        };
    };
    const wsDisconnect = (reason = "user disconnect") => {
        if (ws.current) {

            //console.log("disconnecting");
            ws.current.close(1000, reason);

            // @ts-ignore
            ws.current = null;
        }
        setStatus(ConnectionStatus.Disconnected);
    }


    return {
        wsSend,
        wsConnect,
        wsDisconnect,
        status
    };
};
