import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';

export default function createMyTheme(options: ThemeOptions) {
  return createMuiTheme({
    palette: {
      primary: {
        main: '#FCB005',
        dark: '#FF8A00',
      },
      secondary: {
        main: '#47C072',
      },
    },
    props: {
      MuiButton: {
        disableElevation: true,
      },
    },
    overrides: {
      MuiButton: {
        contained: {
          '@media (hover: none)': {
            '&:hover': {
              backgroundColor: `#FCB005 !important`,
            },
          },
          WebkitTapHighlightColor: '#FCB005',
          backgroundColor: '#FCB005',
          '&:hover': {
            backgroundColor: '#FF8A00',
          },
          '&:active': {
            backgroundColor: '#FCB005',
          },
          '&:focus': {
            backgroundColor: '#FCB005',
          },
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: '#0c643b',
        },
      },
      MuiPaper: {
        root: {
          backgroundColor: '#106E45',
        },
      },
      MuiTextField: {
        root: {
          '& .MuiOutlinedInput-input': {
            color: 'white',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: '#FCB000',
            },
          },
          '& .MuiFormLabel-root': {
            color: 'white',
            '&.Mui-focused': {
              color: '#FCB000',
            },
          },
        },
      },
      MuiSelect: {
        root: {
          '& .MuiButtonBase-root': {
            color: 'white',
          },
          color: 'white',
        },
        icon: {
          color: '#fff',
        },
      },
      MuiFormLabel: {
        root: {
          color: 'white',
        },
      },
      MuiListItem: {
        root: {
          color: 'white',
        },
      },
    },
  });
}
