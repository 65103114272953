import beginImage from '../../assets/beginImage_ee.svg';

const en = {
  translations: {
    global: {
      financialReport: 'Finantsaruanne',
      competitionReport: 'Konkurentsiaruanne',
      homeButton: 'Avaleht',
      cancelButton: 'Tühista',
      closeProjector: 'SUlge projektor',
      openProjector: 'Ava projektor',
      fullscreenButton: 'Vajuta F11 täisekraaniks.',
    },
    translations: {
      ee: '🇪🇪 Eesti',
      en: '🇬🇧 Inglise',
      az: '🇦🇿 Aserbaidžaani',
      ru: '🇷🇺 Vene',
      beginImage: beginImage,
    },
    email: {
      failedTitle: 'Midagi läks valesti',
      failedMessage: 'Palun proovi uuesti.',
      successTitle: 'Õnnestus!',
      emailConfirmed: 'Sinu e-posti ei tuvastatud!',
      userNotFound: 'Selle e-postiga kasutajat ei leitud.',
      alreadyVerified: 'See konto on juba olemas.',
      passwordChanged: 'Sinu salasõna muudeti edukalt.',
      passwordLabel: 'Uus salasõna',
      passwordLabelSecond: 'Korda uut salasõna',
      confirmPassword: 'Kinnita uus salasõna',
      passwordNotMatching: 'Salasõnad peavad ühtima.',
      emailLabel: 'E-post',
      sendResetEmailButton: 'Lähtesta e-post',
    },
    landingPage: {
      landingTitle: 'TURUGURU',
      introText:
        "Lorem ipsum, or lipsum, nagu seda vahel tuntakse, on ajutine tekst, mida kasutatakse trükiste, graafiliste või veebikujunduste kujundamisel. Sellise teksti kasutamist omistatakse 15. sajandil tundmatule trükiladujale, keda arvatakse olevat tsiteerinud osa Cicero teosest De Finibus Bonorum et Malorum, et kasutada neid tüübinäidiste raamatus.",
      joinGameButton: 'Ühine mänguga',
      logInModalButton: 'logi sisse',
      email: 'E-post',
      username: 'Kasutaja',
      logInUsernameError: 'Vale kasutaja.',
      password: 'Salasõna',
      logInPasswordError: 'Vale salasõna.',
      rememberMeLabel: 'Pea mind meeles',
      logInButton: 'Logi sisse',
      aboutUsButton: 'Meist',
      registerButton: 'Registreeri',
      registerEmailTaken: 'E-post on juba kasutusel.',
      incorrectEmailFormat: 'Palun sisesta õige e-post.',
      registerUsernameTaken: 'Nimi on juba kasutusel.',
      registerCreateAccountButton: 'Loo konto',
      passwordConfirm: 'Kinnita salasõna',
      passwordsDoNotMatch: 'Salasõnad ei ühti.',
      nameTooShort: 'Palun sisesta nimi.',
      passwordTooShort: 'Palun sisesta pikem salasõna.',
      disclaimer: 'Seda projekti rahastab Euroopa Liit.',
    },
    errorModal: {
      defaultTitle: 'Oi!',
      defaultMessage: 'Midagi läks valesti.',
      newConnectionTitle: 'Selle kontoga võeti ühendust teisest kohast.',
      newConnectionMessage: 'Ainult üks seade võib olla ühendatud.',
      connectionLostTitle: 'Ühendus kadus',
      connectionLostMessage: 'Kontrolli oma internetiühendust.',
    },
    home: {
      welcomeMessage: 'Tere, {{nimi}}!',
      newGameButton: 'Uus mängu nimi',
      joinGameButton: 'Ühine',
      howToPlayButton: 'Kuidas mängida?',
      accountSettingsButton: 'Konto seaded',
      adminButton: 'admin',
      logOut: 'logi välja',
    },
    admin: {
      usersListTitle: 'Kasutaja',
      filterRole: 'Igaüks',
      adminRole: 'admin',
      teacherRole: 'Õpetaja',
      searchLabel: 'Otsi',
      username: 'Kasutaja',
      email: 'e-post',
      roleLabel: 'roll',
      statusLabel: 'staatus',
      accountActive: 'aktiivne',
      accountInactive: 'mitteaktiivne',
      confirmButton: 'kinnita',
      editButton: 'muuda',
      deleteButton: 'kustuta',
      deleteConfirmation: 'Kas oled kindel, et tahad kustutada kasutajat {{kasutajanime}}?',
      acceptAllChanges: 'Aktsepteeri kõike',
      countryLabel: 'Riik',
    },

    mobileHome: {
      gameTitle: 'TURUGURU',
      codeInput: 'Mängu kood',
      joinGameButton: 'ühine',
      incorrectGameCode: 'Vale kood.',
    },
    mobileNameScreen: {
      nameInput: 'Nimi',
      nameTitle: 'Hüüdnimi',
      nameTakenError: 'Nimi kasutusel.',
      noNameError: 'Nime pole sisestatud.',
      genericNameError: 'Vigane sisestus.',
      joinGameButton: 'JAH',
    },
    playerView: {
      weekTitle: 'Nädal {{nädal}}',
      nextWeek: 'Oota järgmist nädalat.',
      waitForGame: 'Oota mängu algust.',
      financialReport: 'Finantsaruanne',
      resellPriceInput: 'Hulgihind',
      amountToBuyInput: 'Ostetav kogus',
      advertisementInput: 'Reklaam',
      sendGameData: 'Kinnita otsus',
      inputError: 'Sisestusviga.',
      insufficientFundsError: 'Raha ei jätku.',
      tooAccurateError: 'Maksimaalselt 2 komakohta.',
      changeAnswerButton: 'Muuda otsust',
      answerChanged: 'Otsus kinnitatud ✓',
      newWeekAlert: 'Nädal {{nädal}} algas.',
    },
    financialReport: {
      reportTitle: 'Finantsaruanne',
      week: 'nädal',
      wholesalePrice: 'Hulgihind',
      wagesPercent: 'Palgad (%)',
      boardDecision: 'Juhatuse otsused',
      price: 'Hind',
      amount: 'Kogus',
      advertisement: 'Reklaam',
      stock: 'Laovaru',
      stockLastWeek: 'Laovaru enne',
      stockRefill: 'Laovaru täiendamine',
      stockTotal: 'Kokku laos',
      revenue: 'Sissetulekud',
      sellingPrice: 'Müügihind',
      cashFlow: 'Müügitulu',
      expenses: 'Kulud',
      fixedCosts: 'Püsikulud',
      wages: 'Palgad ($)',
      restockCost: 'Kauba ostmiskulud',
      totalCost: 'Kogukulud',
      orders: 'Tellimused',
      totalOrders: 'Tellimused kokku',
      unfulfilledOrders: 'Täitmata tellimused',
      profit: 'Kasum',
      ranking: 'Koht',
      cash: 'Rahaseis',
      leftoverstock: 'laovaru',
      summary: 'Kokkuvõte',
    },
    competitionReport: {
      week: 'nädal',
      competition: 'Konkurentsiaruanne',
      economicStatus: 'Majandusseis',
      totalPlayers: 'Kokku mängijaid',
      averagePrice: 'Keskmine hind',
      soldUnits: 'Müüdud ühikuid',
      stock: 'Laovaru',
      unfulfilledOrders: 'Täitmata tellikused',
      advertisingVolume: 'Reklaamikulud',
      weekResults: 'Nädala tulemused',
      profit: 'Kasum',
      sellingPrice: 'Hind',
      totalProfit: 'Kogukasum',
    },
    teacherReport: {
      week: 'Mängijad (nädal {{nädal}})',
      price: 'Hind',
      amount: 'Kogus',
      advertisement: 'Reklaam',
      stockLastWeek: 'Laovaru enne',
      sold: 'müüdud',
      revenue: 'Sissetulekud',
      fixedCosts: 'Püsikulud',
      wages: 'Palgad ($)',
      restockCost: 'Kauba ostmiskulud',
      totalCost: 'Kogukulud',
      totalOrders: 'Tellimusi kokku',
      unfulfilledOrders: 'Täitmata tellimused',
      leftoverstock: 'laovaru',
      profit: 'Kasum',
      placementThisWeek: 'Selle nädala koht',
      totalProfit: 'Kogukasum',
      ranking: 'Koht',
      cash: 'Rahaseis',
    },
    waitingScreen: {
      playerListTitle: 'MÄNGIJAD',
      closeProjector: 'Sulge projektor',
      openProjector: 'Ava projektor',
      beginGameButton: 'alusta',
    },
    projectorWaitingScreen: {
      gameCodeLabel: 'Mängu kood',
    },
    hostView: {
      windowTooSmall: 'Rakenduse kasutamiseks tee aken suuremaks.',
      eventsTitle: 'Sündmused',
      playersTitle: 'Mängijad',
      graphControlsTitle: 'Graafikud',
      balanceGraph: 'Rahaseis',
      profitGraph: 'Kasum',
      playerPricesGraph: 'Hinnad',
      averagePriceGraph: 'Keskmine hind',
      advertisingVolumeGraph: 'Reklaamikulu',
      totalStockGraph: 'Kokku laovaru',
      unfulfilledOrdersGraph: 'Täitmata tellimused',
      closeProjector: 'Sulge projektor',
      openProjector: 'Ava projektor',
      nextWeekButton: 'järgmine nädal',
      endGameConfirmation: 'Kas oled kindel, et tahad mängu lõpetada?',
      endGameButton: 'lõpeta mäng',
      kickButton: 'vajuta',
      allowAmount: 'Luba Kogus',
      allowAdvertisement: 'Luba Reklaam',
      showNews: 'Näita uudiseid',
      hotWeatherEvent: 'Palav ilm',
      coldWeatherEvent: 'külm ilm',
      cruiseShipEvent: 'kruiisilaev',
      newMovieEvent: 'uus film',
      badTimesEvent: 'halvad ajad',
      teacherReport: 'õpetaja aruanne',
      customEvent: 'Muudetav sündmus',
      demand: 'Nõudlus',
      advert: 'Reklaam',
      wholesalePrice: 'Hulgihind',
      fixedCosts: 'Püsikulud',
      wages: 'Palgad',
      reset: 'Lähtesta',
    },
    projector: {
      tweetUsername: 'Turuanalüütik',
      tweetTag: '@turuanalüütik',
      hotWeatherEventNews: 'Ilmaprognoos lubab järgmiseks nädalaks palavat ilma.',
      coldWeatherEventNews: 'Ilmaprognoos lubab järgmiseks nädalaks vihma.',
      cruiseShipEventNews: 'Teie linna sadamasse saabus mitmeid kruiisilaevu.',
      newMovieEventNews: 'Teles hakati näitama populaarset sarja. Ühe osa jooksul on kolm reklaamipausi. ',
      badTimesEventNews: 'Omanik tõstis laoruumi renti ja hulgimüüja tõstis jäätise hinda',
    },
    endScreen: {
      gameOver: 'Mängu lõpp',
      leaderboardView: 'Edetabel',
      graphView: 'graafikud',
    },
    endScreenPlayer: {
      firstPlace: 'Palju õnne!👑🎉',
      secondPlace: 'Palju õnne!🥈🥳',
      thirdPlace: 'Palju õnne!🥉👔',
      topTen: 'Meistriteliiga!💰📈',
      theRest: 'Ära muretse, järgmisel korral läheb paremini!💪',
    },
  },
};

export default en;
