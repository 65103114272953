import beginImage from '../../assets/beginImage_en.svg';


const en = {
  translations: {
    global: {
      financialReport: 'Financial Report',
      competitionReport: 'Competition Report',
      homeButton: 'Home',
      cancelButton: 'Cancel',
      closeProjector: 'Close projector',
      openProjector: 'Open projector',
      fullscreenButton: 'Press F11 to enter full screen.',
    },
    translations: {
      ee: '🇪🇪 Estonian',
      en: '🇬🇧 English',
      az: '🇦🇿 Azerbaijan',
      ru: '🇷🇺 Russian',
      beginImage: beginImage,
    },
    email: {
      failedTitle: 'Something went wrong',
      failedMessage: 'Please try again.',
      successTitle: 'Success!',
      emailConfirmed: 'Your e-mail is now verified!',
      userNotFound: 'User with this e-mail not found.',
      alreadyVerified: 'This account has already been verified.',
      passwordChanged: 'Your password has been successfully changed.',
      passwordLabel: 'New Password',
      passwordLabelSecond: 'New Password Again',
      confirmPassword: 'Confirm New Password',
      passwordNotMatching: 'The passwords must match.',
      emailLabel: 'Email',
      sendResetEmailButton: 'Reset email',
    },
    landingPage: {
      landingTitle: 'MARKET PRO',
      introText:
        "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or webdesigns. The passage is attributed to an unknown typesetter in the 15th century who is thought to havescrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.",
      joinGameButton: 'join game',
      logInModalButton: 'log in',
      email: 'E-mail',
      username: 'Username',
      logInUsernameError: 'Incorrect username.',
      password: 'Password',
      logInPasswordError: 'Incorrect password.',
      rememberMeLabel: 'Remember me',
      logInButton: 'Log in',
      aboutUsButton: 'About us',
      registerButton: 'Register',
      registerEmailTaken: 'Email already in use.',
      incorrectEmailFormat: 'Please enter a correct email.',
      registerUsernameTaken: 'Name already in use.',
      registerCreateAccountButton: 'create account',
      passwordConfirm: 'Confirm password',
      passwordsDoNotMatch: 'The passwords do not match.',
      nameTooShort: 'Please enter a name.',
      passwordTooShort: 'Please enter a longer password.',
      disclaimer: 'This project is funded by the European Union.',
    },
    errorModal: {
      defaultTitle: 'Oops!',
      defaultMessage: 'Something went wrong.',
      newConnectionTitle: 'This account has connected from another location.',
      newConnectionMessage: 'Only one device can be connected.',
      connectionLostTitle: 'Connection lost',
      connectionLostMessage: 'Check your internet connection.',
    },
    home: {
      welcomeMessage: 'Welcome, {{name}}!',
      newGameButton: 'new game',
      joinGameButton: 'join',
      howToPlayButton: 'how to play?',
      accountSettingsButton: 'account settings',
      adminButton: 'admin',
      logOut: 'log out',
    },
    admin: {
      usersListTitle: 'Users',
      filterRole: 'any',
      adminRole: 'admin',
      teacherRole: 'teacher',
      searchLabel: 'Search',
      username: 'username',
      email: 'email',
      roleLabel: 'role',
      statusLabel: 'status',
      accountActive: 'active',
      accountInactive: 'inactive',
      confirmButton: 'confirm',
      editButton: 'edit',
      deleteButton: 'delete',
      deleteConfirmation: 'Are you sure you want to delete user {{username}}?',
      acceptAllChanges: 'Accept All',
      countryLabel: 'country',
    },

    mobileHome: {
      gameTitle: 'MARKET PRO',
      codeInput: 'Game code',
      joinGameButton: 'join',
      incorrectGameCode: 'Incorrect code.',
    },
    mobileNameScreen: {
      nameInput: 'Name',
      nameTitle: 'Nickname',
      nameTakenError: 'Name taken.',
      noNameError: 'No name entered.',
      genericNameError: 'Invalid input.',
      joinGameButton: 'OK',
    },
    playerView: {
      weekTitle: 'Week {{week}}',
      nextWeek: 'Wait for the next week.',
      waitForGame: 'Wait for the game to begin.',
      financialReport: 'Financial Report',
      resellPriceInput: 'Resell Price',
      amountToBuyInput: 'Buy Amount',
      advertisementInput: 'Advertisement',
      sendGameData: 'Confirm decision',
      inputError: 'Input error.',
      insufficientFundsError: 'Insufficient funds.',
      tooAccurateError: 'Answer is limited to 2 decimal points.',
      changeAnswerButton: 'Change decision',
      answerChanged: 'Decision Confirmed ✓',
      newWeekAlert: 'Week {{week}} has begun.',
    },
    financialReport: {
      reportTitle: 'Financial Report',
      week: 'week',
      wholesalePrice: 'Wholesale Price',
      wagesPercent: 'Wages (%)',
      boardDecision: 'Board Decisions',
      price: 'Price',
      amount: 'Amount',
      advertisement: 'Advertisement',
      stock: 'Stock',
      stockLastWeek: 'Stock before',
      stockRefill: 'Stock refill',
      stockTotal: 'Total stock',
      revenue: 'Revenue',
      sellingPrice: 'Selling price',
      cashFlow: 'Cash flow',
      expenses: 'Expenses',
      fixedCosts: 'Fixed costs',
      wages: 'Wages ($)',
      restockCost: 'Restock cost',
      totalCost: 'Total cost',
      orders: 'Orders',
      totalOrders: 'Total orders',
      unfulfilledOrders: 'Unfulfilled orders',
      profit: 'Profit',
      ranking: 'Rank',
      cash: 'Cash',
      leftoverstock: 'stock remaining',
      summary: 'summary',
    },
    competitionReport: {
      week: 'week',
      competition: 'Competition Report',
      economicStatus: 'Economic status',
      totalPlayers: 'Total players',
      averagePrice: 'Average price',
      soldUnits: 'Units sold',
      stock: 'Stock',
      unfulfilledOrders: 'Unfulfilled orders',
      advertisingVolume: 'Advertising volume',
      weekResults: 'Week results',
      profit: 'Profit',
      sellingPrice: 'Price',
      totalProfit: 'Total Profit',
    },
    teacherReport: {
      week: 'Players (week {{week}})',
      price: 'Price',
      amount: 'Amount',
      advertisement: 'Advertisement',
      stockLastWeek: 'Stock before',
      sold: 'sold',
      revenue: 'Revenue',
      fixedCosts: 'Fixed costs',
      wages: 'Wages ($)',
      restockCost: 'Restock cost',
      totalCost: 'Total cost',
      totalOrders: 'Total orders',
      unfulfilledOrders: 'Unfulfilled orders',
      leftoverstock: 'stock remaining',
      profit: 'Profit',
      placementThisWeek: 'Placement this week',
      totalProfit: 'Total Profit',
      ranking: 'Rank',
      cash: 'Cash',
    },
    waitingScreen: {
      playerListTitle: 'PLAYERS',
      closeProjector: 'Close projector',
      openProjector: 'Open projector',
      beginGameButton: 'begin',
    },
    projectorWaitingScreen: {
      gameCodeLabel: 'game code',
    },
    hostView: {
      windowTooSmall: 'In order to use the application, make your window bigger.',
      eventsTitle: 'Events',
      playersTitle: 'Players',
      graphControlsTitle: 'Projector Graph',
      balanceGraph: 'Balance',
      profitGraph: 'Profit',
      playerPricesGraph: 'Prices',
      averagePriceGraph: 'Average Price',
      advertisingVolumeGraph: 'Advertising Volume',
      totalStockGraph: 'Total Stock',
      unfulfilledOrdersGraph: 'Unfulfilled Orders',
      closeProjector: 'Close projector',
      openProjector: 'Open projector',
      nextWeekButton: 'next week',
      endGameConfirmation: 'Are you sure you want to end the game?',
      endGameButton: 'end game',
      kickButton: 'kick',
      allowAmount: 'Allow Amount',
      allowAdvertisement: 'Allow Advertisement',
      showNews: 'Show news',
      hotWeatherEvent: 'Hot Weather',
      coldWeatherEvent: 'cold weather',
      cruiseShipEvent: 'cruise ship',
      newMovieEvent: 'new movie',
      badTimesEvent: 'bad times',
      teacherReport: 'teacher report',
      customEvent: 'Custom event',
      demand: 'Demand',
      advert: 'Advertisement',
      wholesalePrice: 'Wholesale Price',
      fixedCosts: 'Fixed costs',
      wages: 'Wages',
      reset: 'reset',
    },
    projector: {
      tweetUsername: 'Market Analyst',
      tweetTag: '@marketanalyst',
      hotWeatherEventNews: 'The weather forecast shows hot weather for the next week.',
      coldWeatherEventNews: 'The weather forecast shows cold weather for the next week.',
      cruiseShipEventNews: 'A lot of ships arrive at the port.',
      newMovieEventNews: 'A very popular TV show has aired, showing ice cream advertisements.',
      badTimesEventNews: 'The landlord raised the rent and the wholesaler increased the price',
    },
    endScreen: {
      gameOver: 'Game over',
      leaderboardView: 'Leaderboard',
      graphView: 'graphs',
    },
    endScreenPlayer: {
      firstPlace: 'Congratulations!👑🎉',
      secondPlace: 'Congratulations!🥈🥳',
      thirdPlace: 'Congratulations!🥉👔',
      topTen: 'Up there in the big league!💰📈',
      theRest: 'No worries, better luck next time!💪',
    },
  },
};

export default en;
