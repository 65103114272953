import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from '@reach/router';
import { useQueryParams } from '../../../../lib/UseQueryParams';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import styles from './CompetitionReport.module.scss';
import { PlayerHistoryInfo } from '../PlayerView/PlayerView';
import { roundNumber } from '../../../../lib/helpers';
import { useTranslation } from 'react-i18next';
import RedCloseButton from '../../../../assets/RedCloseButton.svg';
import useDragScroll from '../../../../lib/UseDragScroll';
import { Round } from '../HostView/HostView';

export interface Props {
  round: Round;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  week: number;
}

function TeacherReport(props: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useQueryParams();
  const [lastOpen, setLastOpen] = useState(false);
  const openModal = props.isOpen;
  const setOpenModal = props.setIsOpen;
  const info = props.round.info;
  useEffect(() => {
    if (lastOpen !== openModal) {
      onOpenModal(openModal);
      setLastOpen(openModal);
    } else {
      setOpenModal(!!params.teacherOpen);
      setLastOpen(!!params.teacherOpen);
    }
  }, [openModal, params.teacherOpen]);

  const onOpenModal = async (open: boolean) => {
    if (open && !params.teacherOpen) {
      navigate(location.pathname + '?teacherOpen=true');
    } else if (params.teacherOpen) {
      navigate(location.pathname);
    }
  };
  const placementSingleWeek = (username: string) => {
    //@ts-ignore
    let placements = [];
    let sortedMap = new Map<string,number>();
    props.round.players.forEach((value, key) => {
      placements.push([key, value.profit]);
    });
    //@ts-ignore
    placements.sort((a, b) => b[1] - a[1]).map((pair, i) => sortedMap.set(pair[0], i + 1));
    let e = sortedMap.get(username);
    return e || 0;
  };
  const getData = (func: (data: PlayerHistoryInfo, key: string) => number | string): JSX.Element[] => {
    let ret: JSX.Element[] = [];

    

    props.round.players.forEach((value, key) => {
      let v = func(value, key);
      if(typeof v === "number"){
          v = roundNumber(v,2);
      }
      ret.push(<td>{v}</td>);
    });
    return ret;
  };
  const dragProps = useDragScroll<HTMLDivElement>();
  return (
    <>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="financial report"
        aria-describedby="financial report"
        style={{ height: window.innerHeight }}
        fullScreen
      >
        <DialogTitle>
          <div className={styles.reportBar}>
            <p className={styles.reportTitle}>{t('competitionReport.competition')}</p>
            <IconButton >
              <img src={RedCloseButton} className={styles.closeButton} onClick={() => setOpenModal(false)} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers style={{ padding: '0' }} {...dragProps}>
          <table>
            <tbody>
              <tr>
                <th>{t('teacherReport.week', { week: props.week })}</th>
                {getData((_, i) => i)}
              </tr>
              <tr>
                <th>{t('teacherReport.price')}</th>
                {getData((data) => data.price)}
              </tr>
              <tr>
                <th>{t('teacherReport.amount')}</th>
                {getData((data) => data.amount)}
              </tr>
              <tr>
                <th>{t('teacherReport.advertisement')}</th>
                {getData((data) => data.advertisement)}
              </tr>
              
              <tr>
                <th>{t('teacherReport.sold')}</th>
                {getData((data) => data.sold)}
              </tr>
              <tr>
                <th>{t('teacherReport.revenue')}</th>
                {getData((data) => data.sold * data.price)}
              </tr>
              <tr>
                <th>{t('teacherReport.fixedCosts')}</th>
                {getData((data) => props.round.info.fixedCosts)}
              </tr>
              <tr>
                <th>{t('teacherReport.wages')}</th>
                {getData((data) => data.sold * info.wages)}
              </tr>
              <tr>
                <th>{t('teacherReport.restockCost')}</th>
                {getData((data) => data.amount * info.wholesalePrice)}
              </tr>
              <tr>
                <th>{t('teacherReport.totalCost')}</th>
                {getData(
                  (data) =>
                    data.amount * info.wholesalePrice +
                    data.sold * data.price * info.wages +
                    data.advertisement * info.fixedCosts
                )}
              </tr>
              <tr>
                <th>{t('teacherReport.totalOrders')}</th>
                {getData((data) => data.totalOrders)}
              </tr>
              <tr>
                <th>{t('teacherReport.unfulfilledOrders')}</th>
                {getData((data) => data.totalOrders - data.sold)}
              </tr>
              <tr>
                <th>{t('teacherReport.leftoverstock')}</th>
                {getData((data) => data.amount + data.startStock - data.sold)}
              </tr>
              <tr>
                <th>{t('teacherReport.profit')}</th>
                {getData((data) => data.profit)}
              </tr>
              <tr>
                <th>{t('teacherReport.placementThisWeek')}</th>
                {getData((_, i) => placementSingleWeek(i))}
              </tr>
              <tr>
                <th>{t('teacherReport.totalProfit')}</th>
                {getData((data) => data.endBalance - 1000)}
              </tr>
              <tr>
                <th>{t('teacherReport.ranking')}</th>
                {getData((data) => data.ranking)}
              </tr>

              <tr>
                <th>{t('teacherReport.cash')}</th>
                {getData((data) => data.endBalance)}
              </tr>
            </tbody>
          </table>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default TeacherReport;
